import { Pipe, PipeTransform } from '@angular/core';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreCarrierLookupService } from './CoreCarrierLookupService';
@Pipe({
	name: 'carrierName'
})
export class CarrierNamePipe implements PipeTransform {
	constructor(protected service: CoreCarrierLookupService) {
	}
	transform(value: number, ...args: any[]) {
		if (!value) {
			return of(null);
		}
		return this.service.getItem(value).pipe(map(data => { if (!data) { return null; } return data.name; }));
	}
}
