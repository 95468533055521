import { Component, EventEmitter, forwardRef, Input, Output } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { CarrierLookupModel } from 'app/generated/backend/party/api/carrier-lookup-model';
import { BaseIdInputTypeaheadComponent } from 'app/_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent';
import { CoreCarrierLookupService } from './CoreCarrierLookupService';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
  provide: NG_VALUE_ACCESSOR,
  useExisting: forwardRef(() => CoreCarrierLookupComponent),
  multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreCarrierLookupComponent),
	multi: true
};

@Component({
  selector: 'app-carrier-lookup',
	templateUrl: '../../_templates/base-id-input-typeahead/BaseIdInputTypeaheadComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})

export class CoreCarrierLookupComponent extends BaseIdInputTypeaheadComponent<CarrierLookupModel> {

  @Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false; 

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

  @Output()
  itemSelected: EventEmitter<CarrierLookupModel> = new EventEmitter<CarrierLookupModel>();

  constructor(protected service: CoreCarrierLookupService) {
    super(service);
  }

  toDisplayText(item: CarrierLookupModel): string {
    if (!item) {
      return null;
    }
    return item.name;
  }

  toOptionText(item: CarrierLookupModel): string {
		return this.toDisplayText(item);
	}


}