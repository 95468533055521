import { Component, Input } from '@angular/core';

@Component({
	selector: 'app-form-field-display',
	templateUrl: './CoreFormFieldDisplayComponent.html'
})
export class CoreFormFieldDisplayComponent {
	@Input()
	helpUri: string;

	@Input()
	label: string;

	@Input()
	iconClass: string;

	@Input()
	formFieldClass: string = 'adn-form-field-disabled';

	@Input()
	labelBoldClass: string = '';

	public constructor(
	) {
	}

}
