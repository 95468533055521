import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Menu } from './Menu';

@Component({
	selector: 'app-menu',
	templateUrl: './MenuComponent.html'
})
export class MenuComponent {
	@Input()
	menu: Menu;
	@Input()
	activeItemClass: string;
	@Input()
	itemClass: string;
	@Input()
	caret = false;

	public isExpanded = false;

}
