import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum Feature {
	None = 0,
	ConsolidatedCustomerInvoicing = 1,
	DocumentArchive = 2,
	PurchaseOrders = 3,
	Receiving = 4,
	Lcl = 5,
	CargoDetails = 6,
	SalesOrders = 7,
	CargoDetailVolumeAndWeight = 8,
	CargoDetailValue = 9,
	Scanning = 10,
	PackingList = 11,
	DeviceTracking = 12,
	PartyRateContracts = 13,
	ControlTower = 14,
	DirectCarrierBooking = 15,
}

@Pipe({
name: 'feature'
})
export class FeaturePipe extends BasePipe<Feature> implements PipeTransform {
	static prefix = 'Backend.Types.Feature.';
	static keys = [
		Feature.None,
		Feature.ConsolidatedCustomerInvoicing,
		Feature.DocumentArchive,
		Feature.PurchaseOrders,
		Feature.Receiving,
		Feature.Lcl,
		Feature.CargoDetails,
		Feature.SalesOrders,
		Feature.CargoDetailVolumeAndWeight,
		Feature.CargoDetailValue,
		Feature.Scanning,
		Feature.PackingList,
		Feature.DeviceTracking,
		Feature.PartyRateContracts,
		Feature.ControlTower,
		Feature.DirectCarrierBooking];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: Feature): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case Feature.None: return 'None';
			case Feature.ConsolidatedCustomerInvoicing: return 'ConsolidatedCustomerInvoicing';
			case Feature.DocumentArchive: return 'DocumentArchive';
			case Feature.PurchaseOrders: return 'PurchaseOrders';
			case Feature.Receiving: return 'Receiving';
			case Feature.Lcl: return 'Lcl';
			case Feature.CargoDetails: return 'CargoDetails';
			case Feature.SalesOrders: return 'SalesOrders';
			case Feature.CargoDetailVolumeAndWeight: return 'CargoDetailVolumeAndWeight';
			case Feature.CargoDetailValue: return 'CargoDetailValue';
			case Feature.Scanning: return 'Scanning';
			case Feature.PackingList: return 'PackingList';
			case Feature.DeviceTracking: return 'DeviceTracking';
			case Feature.PartyRateContracts: return 'PartyRateContracts';
			case Feature.ControlTower: return 'ControlTower';
			case Feature.DirectCarrierBooking: return 'DirectCarrierBooking';
			default: return null;
		}
	}
	transform(value: Feature, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(FeaturePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		FeaturePipe
	],
	exports: [
		FeaturePipe
	],
	providers: [
		FeaturePipe
	]
})
export class FeatureModule { }
