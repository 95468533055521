import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { TranshipmentTypeModule } from 'app/generated/backend/types/transhipment-type';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { TranshipmentTypeInputComponent } from './TranshipmentTypeInputComponent';
import { TranshipmentTypeFlagCheckBoxComponent } from 'app/core/transhipment-type-input/TranshipmentTypeFlagCheckBoxComponent';

@NgModule({
    imports: [
        AutocompleteModule,
        CommonModule,
        FormsModule,
        TranslateModule,
        CommonMaterialModule,
        TranshipmentTypeModule,
        HelpPopupModule
    ],
    exports: [TranshipmentTypeInputComponent, TranshipmentTypeFlagCheckBoxComponent],
    declarations: [TranshipmentTypeInputComponent, TranshipmentTypeFlagCheckBoxComponent]
})
export class TranshipmentTypeInputModule {
}
