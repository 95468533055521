import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum AdminAccessControl {
	Shipments = 0,
	Containers = 1,
	Locations = 2,
	TradeLanes = 3,
	Vessels = 4,
	RateChart = 5,
	Media = 6,
	Content = 7,
	Templates = 8,
	Parties = 9,
	Users = 10,
	Countries = 11,
	Currencies = 12,
	HarmonizedCommodities = 13,
	HazardousSubstances = 14,
	PackageTypes = 15,
	Incoterms = 16,
	RateIndexes = 17,
	Daemons = 18,
	Jobs = 20,
	Edi = 21,
	Languages = 22,
	Translations = 23,
	Analytics = 24,
	DocumentTypes = 25,
	PartySeaRateCurrent = 26,
	Message = 27,
	PartyTasks = 28,
	ShipmentServices = 29,
	PartyAccountTransactions = 30,
	Invoices = 31,
	ShipmentCharges = 32,
	Routes = 33,
	ShipmentServiceRejections = 34,
	ShipmentBookings = 35,
	ShipmentVoyages = 36,
	PartyUserActivity = 37,
	Reserved38 = 38,
	UserGroup = 39,
	CompanyCredit = 40,
	CompanyIdentity = 41,
	Reserved42 = 42,
	PartyFinance = 43,
	PartyFeatures = 44,
	PartyMerge = 45,
	Parameters = 46,
	ShipmentParties = 47,
	ShipmentReleases = 48,
	CountryCountryTradeLane = 49,
	OcrJob = 50,
	Contacts = 51,
	Companies = 52,
	Localities = 53,
	SeaSchedules = 54,
	PartyShipmentTrackingStatus = 55,
	Locale = 56,
	Topic = 57,
	Campaigns = 58,
	PartyContacts = 59,
	CountryCalendar = 60,
	UserInfoProcessing = 61,
	ContentTranslation = 62,
	CountryPhoneNumberPlan = 63,
	SupportIssue = 64,
	IntegrationEvents = 65,
	SupportEpic = 66,
	RailSchedules = 67,
	TradeLaneProviders = 68,
	Facilities = 69,
	VesselPortCalls = 70,
	Deal = 71,
	Agreements = 72,
	CompanyMerge = 73,
	SeasonalityProfiles = 74,
	CompanyActivities = 75,
	PurchaseOrders = 76,
	IndustrialClassifications = 77,
	PartyTeam = 78,
	PartyContainerCodes = 79,
	TimeEntry = 80,
	SalesOrders = 81,
	MaxAccessControl = 82,
}

@Pipe({
name: 'adminAccessControl'
})
export class AdminAccessControlPipe extends BasePipe<AdminAccessControl> implements PipeTransform {
	static prefix = 'Backend.Types.AdminAccessControl.';
	static keys = [
		AdminAccessControl.Shipments,
		AdminAccessControl.Containers,
		AdminAccessControl.Locations,
		AdminAccessControl.TradeLanes,
		AdminAccessControl.Vessels,
		AdminAccessControl.RateChart,
		AdminAccessControl.Media,
		AdminAccessControl.Content,
		AdminAccessControl.Templates,
		AdminAccessControl.Parties,
		AdminAccessControl.Users,
		AdminAccessControl.Countries,
		AdminAccessControl.Currencies,
		AdminAccessControl.HarmonizedCommodities,
		AdminAccessControl.HazardousSubstances,
		AdminAccessControl.PackageTypes,
		AdminAccessControl.Incoterms,
		AdminAccessControl.RateIndexes,
		AdminAccessControl.Daemons,
		AdminAccessControl.Jobs,
		AdminAccessControl.Edi,
		AdminAccessControl.Languages,
		AdminAccessControl.Translations,
		AdminAccessControl.Analytics,
		AdminAccessControl.DocumentTypes,
		AdminAccessControl.PartySeaRateCurrent,
		AdminAccessControl.Message,
		AdminAccessControl.PartyTasks,
		AdminAccessControl.ShipmentServices,
		AdminAccessControl.PartyAccountTransactions,
		AdminAccessControl.Invoices,
		AdminAccessControl.ShipmentCharges,
		AdminAccessControl.Routes,
		AdminAccessControl.ShipmentServiceRejections,
		AdminAccessControl.ShipmentBookings,
		AdminAccessControl.ShipmentVoyages,
		AdminAccessControl.PartyUserActivity,
		AdminAccessControl.Reserved38,
		AdminAccessControl.UserGroup,
		AdminAccessControl.CompanyCredit,
		AdminAccessControl.CompanyIdentity,
		AdminAccessControl.Reserved42,
		AdminAccessControl.PartyFinance,
		AdminAccessControl.PartyFeatures,
		AdminAccessControl.PartyMerge,
		AdminAccessControl.Parameters,
		AdminAccessControl.ShipmentParties,
		AdminAccessControl.ShipmentReleases,
		AdminAccessControl.CountryCountryTradeLane,
		AdminAccessControl.OcrJob,
		AdminAccessControl.Contacts,
		AdminAccessControl.Companies,
		AdminAccessControl.Localities,
		AdminAccessControl.SeaSchedules,
		AdminAccessControl.PartyShipmentTrackingStatus,
		AdminAccessControl.Locale,
		AdminAccessControl.Topic,
		AdminAccessControl.Campaigns,
		AdminAccessControl.PartyContacts,
		AdminAccessControl.CountryCalendar,
		AdminAccessControl.UserInfoProcessing,
		AdminAccessControl.ContentTranslation,
		AdminAccessControl.CountryPhoneNumberPlan,
		AdminAccessControl.SupportIssue,
		AdminAccessControl.IntegrationEvents,
		AdminAccessControl.SupportEpic,
		AdminAccessControl.RailSchedules,
		AdminAccessControl.TradeLaneProviders,
		AdminAccessControl.Facilities,
		AdminAccessControl.VesselPortCalls,
		AdminAccessControl.Deal,
		AdminAccessControl.Agreements,
		AdminAccessControl.CompanyMerge,
		AdminAccessControl.SeasonalityProfiles,
		AdminAccessControl.CompanyActivities,
		AdminAccessControl.PurchaseOrders,
		AdminAccessControl.IndustrialClassifications,
		AdminAccessControl.PartyTeam,
		AdminAccessControl.PartyContainerCodes,
		AdminAccessControl.TimeEntry,
		AdminAccessControl.SalesOrders,
		AdminAccessControl.MaxAccessControl];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: AdminAccessControl): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case AdminAccessControl.Shipments: return 'Shipments';
			case AdminAccessControl.Containers: return 'Containers';
			case AdminAccessControl.Locations: return 'Locations';
			case AdminAccessControl.TradeLanes: return 'TradeLanes';
			case AdminAccessControl.Vessels: return 'Vessels';
			case AdminAccessControl.RateChart: return 'RateChart';
			case AdminAccessControl.Media: return 'Media';
			case AdminAccessControl.Content: return 'Content';
			case AdminAccessControl.Templates: return 'Templates';
			case AdminAccessControl.Parties: return 'Parties';
			case AdminAccessControl.Users: return 'Users';
			case AdminAccessControl.Countries: return 'Countries';
			case AdminAccessControl.Currencies: return 'Currencies';
			case AdminAccessControl.HarmonizedCommodities: return 'HarmonizedCommodities';
			case AdminAccessControl.HazardousSubstances: return 'HazardousSubstances';
			case AdminAccessControl.PackageTypes: return 'PackageTypes';
			case AdminAccessControl.Incoterms: return 'Incoterms';
			case AdminAccessControl.RateIndexes: return 'RateIndexes';
			case AdminAccessControl.Daemons: return 'Daemons';
			case AdminAccessControl.Jobs: return 'Jobs';
			case AdminAccessControl.Edi: return 'Edi';
			case AdminAccessControl.Languages: return 'Languages';
			case AdminAccessControl.Translations: return 'Translations';
			case AdminAccessControl.Analytics: return 'Analytics';
			case AdminAccessControl.DocumentTypes: return 'DocumentTypes';
			case AdminAccessControl.PartySeaRateCurrent: return 'PartySeaRateCurrent';
			case AdminAccessControl.Message: return 'Message';
			case AdminAccessControl.PartyTasks: return 'PartyTasks';
			case AdminAccessControl.ShipmentServices: return 'ShipmentServices';
			case AdminAccessControl.PartyAccountTransactions: return 'PartyAccountTransactions';
			case AdminAccessControl.Invoices: return 'Invoices';
			case AdminAccessControl.ShipmentCharges: return 'ShipmentCharges';
			case AdminAccessControl.Routes: return 'Routes';
			case AdminAccessControl.ShipmentServiceRejections: return 'ShipmentServiceRejections';
			case AdminAccessControl.ShipmentBookings: return 'ShipmentBookings';
			case AdminAccessControl.ShipmentVoyages: return 'ShipmentVoyages';
			case AdminAccessControl.PartyUserActivity: return 'PartyUserActivity';
			case AdminAccessControl.Reserved38: return 'Reserved38';
			case AdminAccessControl.UserGroup: return 'UserGroup';
			case AdminAccessControl.CompanyCredit: return 'CompanyCredit';
			case AdminAccessControl.CompanyIdentity: return 'CompanyIdentity';
			case AdminAccessControl.Reserved42: return 'Reserved42';
			case AdminAccessControl.PartyFinance: return 'PartyFinance';
			case AdminAccessControl.PartyFeatures: return 'PartyFeatures';
			case AdminAccessControl.PartyMerge: return 'PartyMerge';
			case AdminAccessControl.Parameters: return 'Parameters';
			case AdminAccessControl.ShipmentParties: return 'ShipmentParties';
			case AdminAccessControl.ShipmentReleases: return 'ShipmentReleases';
			case AdminAccessControl.CountryCountryTradeLane: return 'CountryCountryTradeLane';
			case AdminAccessControl.OcrJob: return 'OcrJob';
			case AdminAccessControl.Contacts: return 'Contacts';
			case AdminAccessControl.Companies: return 'Companies';
			case AdminAccessControl.Localities: return 'Localities';
			case AdminAccessControl.SeaSchedules: return 'SeaSchedules';
			case AdminAccessControl.PartyShipmentTrackingStatus: return 'PartyShipmentTrackingStatus';
			case AdminAccessControl.Locale: return 'Locale';
			case AdminAccessControl.Topic: return 'Topic';
			case AdminAccessControl.Campaigns: return 'Campaigns';
			case AdminAccessControl.PartyContacts: return 'PartyContacts';
			case AdminAccessControl.CountryCalendar: return 'CountryCalendar';
			case AdminAccessControl.UserInfoProcessing: return 'UserInfoProcessing';
			case AdminAccessControl.ContentTranslation: return 'ContentTranslation';
			case AdminAccessControl.CountryPhoneNumberPlan: return 'CountryPhoneNumberPlan';
			case AdminAccessControl.SupportIssue: return 'SupportIssue';
			case AdminAccessControl.IntegrationEvents: return 'IntegrationEvents';
			case AdminAccessControl.SupportEpic: return 'SupportEpic';
			case AdminAccessControl.RailSchedules: return 'RailSchedules';
			case AdminAccessControl.TradeLaneProviders: return 'TradeLaneProviders';
			case AdminAccessControl.Facilities: return 'Facilities';
			case AdminAccessControl.VesselPortCalls: return 'VesselPortCalls';
			case AdminAccessControl.Deal: return 'Deal';
			case AdminAccessControl.Agreements: return 'Agreements';
			case AdminAccessControl.CompanyMerge: return 'CompanyMerge';
			case AdminAccessControl.SeasonalityProfiles: return 'SeasonalityProfiles';
			case AdminAccessControl.CompanyActivities: return 'CompanyActivities';
			case AdminAccessControl.PurchaseOrders: return 'PurchaseOrders';
			case AdminAccessControl.IndustrialClassifications: return 'IndustrialClassifications';
			case AdminAccessControl.PartyTeam: return 'PartyTeam';
			case AdminAccessControl.PartyContainerCodes: return 'PartyContainerCodes';
			case AdminAccessControl.TimeEntry: return 'TimeEntry';
			case AdminAccessControl.SalesOrders: return 'SalesOrders';
			case AdminAccessControl.MaxAccessControl: return 'MaxAccessControl';
			default: return null;
		}
	}
	transform(value: AdminAccessControl, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(AdminAccessControlPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		AdminAccessControlPipe
	],
	exports: [
		AdminAccessControlPipe
	],
	providers: [
		AdminAccessControlPipe
	]
})
export class AdminAccessControlModule { }
