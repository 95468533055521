import { Component, Input } from "@angular/core";

@Component({
    selector: 'app-select-option',
    template: ``
})
export class CoreSelectOptionComponent {

    private _value: number;
    private _label: string;
    private _hint: string;

    @Input()
    public get value(): number {
        return this._value;
    }
    public set value(value: number) {
        this._value = value;
    }

    @Input()
    public get label(): string {
        return this._label;
    }
    public set label(value: string) {
        if (value && value !== this._label) {
            this._label = value;
        }
    }

    @Input()
    public get hint(): string {
        return this._hint;
    }
    public set hint(value: string) {
        if (value && value !== this._hint) {
            this._hint = value;
        }
    }
}