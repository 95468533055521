import { Injectable } from '@angular/core';
import { CarrierLookupModel } from 'app/generated/backend/party/api/carrier-lookup-model';
import { LclCarrierLookupHandlerService } from 'app/generated/backend/party/service/lcl-carrier-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CoreCacheService } from '../cache/CoreCacheService';

@Injectable()
export class CoreLclCarrierLookupService extends CachedEntityService<CarrierLookupModel> {
	constructor(
		service: LclCarrierLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(service, cacheService.getCarriers());
	}
	transformItem(item: CarrierLookupModel): CarrierLookupModel {
		if (item) {
			item.match = '';
			if (item.name) {
				item.match += item.name.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
