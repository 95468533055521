import { Component, EventEmitter, forwardRef, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { CoreCountryLookupService } from 'app/core/country/CoreCountryLookupService';
import { PhoneNumberLookupResult } from 'app/generated/backend/comm/api/phone-number-lookup-result';
import { PhoneNumberLookupHandlerService } from 'app/generated/backend/comm/service/phone-number-lookup-handler';
import { CountryLookupModel } from 'app/generated/backend/locality/api/country-lookup-model';
import { PhoneNumberType } from 'app/generated/backend/types/phone-number-type';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';


const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CorePhoneNumberInputComponent),
	multi: true
};
export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CorePhoneNumberInputComponent),
	multi: true
};

function isNumeric(value: string): boolean {
	if (value) {
		for (let i = 0; i < value.length; i++) {
			const c = value.charAt(i);
			if (c < '0' || c > '9') {
				return false;
			}
		}
	}
	return true;
}

@Component({
	selector: 'app-phone-number-input',
	templateUrl: './CorePhoneNumberInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CorePhoneNumberInputComponent implements ControlValueAccessor, Validator, OnInit, OnDestroy {
	@Input()
	required = false;

	@Input()
	rows: number;

	@Input()
	disabled: boolean;

	@Input()
	helpUri: string;

	@Input()
	label: string;

	@Input()
	placeholder: string = '';

	@Input()
	hint: string;

	@Input()
	maxlength: number;

	@Input()
	minlength: number;

	@Input()
	iconClass: string = 'fa-phone';

	@Input()
	type: PhoneNumberType;

	@Output()
	blur: EventEmitter<any> = new EventEmitter<any>();

	@Output()
	focus: EventEmitter<any> = new EventEmitter<any>();

	@Output()
	countrySelected: EventEmitter<CountryLookupModel> = new EventEmitter<CountryLookupModel>();

	private innerValue: string;
	public phoneNumber: PhoneNumberLookupResult;
	public country: CountryLookupModel = null;
	public loading = true;
	public errorStateMatcher = new SimpleErrorStateMatcher();
	private maxPhonePrefixLength: number;
	private _validatedValue: string;
	protected subscriptions = new Array<Subscription>();
	private countries: CountryLookupModel[];
	private countryByPhonePrefix: Map<string, CountryLookupModel> = new Map<string, CountryLookupModel>();

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): string {
		return this.innerValue;
	};

	// returns null when valid else the validation object
	// in this case we're checking if the json parsing has
	// passed or failed from the onChange method
	public validate(c: UntypedFormControl): ValidationErrors | null {
		if ((this.value == null || this.value.length < 1) && !this.required) {
			this.errorStateMatcher.valid = true;
			this.errorStateMatcher.errorKey = null;
			return null;
		}
		if (this.loading) {
			this.errorStateMatcher.errorKey = 'field.phoneNumber.loading';
			this.errorStateMatcher.valid = false;
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		if (this.value == null || this.country == null || this.value.length < 8 || this.value.length > 15 || !isNumeric(this.value)) {
			this.errorStateMatcher.errorKey = 'field.phoneNumber.formatError';
			this.errorStateMatcher.valid = false;
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		if (this.phoneNumber && !this.phoneNumber.type) {
			this.errorStateMatcher.errorKey = 'field.phoneNumber.validationError';
			this.errorStateMatcher.valid = false;
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		if (this.type && this.phoneNumber && this.phoneNumber.type !== PhoneNumberType.GeneralPurpose && this.phoneNumber.type !== this.type) {
			this.errorStateMatcher.errorKey = 'field.phoneNumber.typeError';
			if (this.type === PhoneNumberType.Landline) {
				this.errorStateMatcher.errorKey = 'field.phoneNumber.landLineTypeError';
			}
			this.errorStateMatcher.valid = false;
			return {
				jsonParseError: {
					valid: false,
				},
			};
		}
		this.errorStateMatcher.valid = true;
		this.errorStateMatcher.errorKey = null;
		return null;
	}

	// set accessor including call the onchange callback
	set value(value: string) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.processPhoneNumber();
		}
	}

	// From ControlValueAccessor interface
	writeValue(value: any) {
		if (value !== this.innerValue) {
			this.innerValue = value;
			this.processPhoneNumber();
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	constructor(
		private countryService: CoreCountryLookupService,
		private phoneNumberLookupService: PhoneNumberLookupHandlerService
	) {
	}

	ngOnInit() {
		if (this.type === 1) {
			this.iconClass = 'fa-mobile-android-alt';
		}
		this.loading = true;
		this.subscriptions.push(this.countryService.getItems().subscribe(
			data => {
				this.countries = data;
				this.maxPhonePrefixLength = 0;
				if (this.countries != null) {
					this.countries.forEach(country => {
						if (country.phonePrefix) {
							const phonePrefix = country.phonePrefix.toString();
							if (phonePrefix.length > this.maxPhonePrefixLength) {
								this.maxPhonePrefixLength = phonePrefix.length;
							}
							this.countryByPhonePrefix.set(phonePrefix, country);
						}
					});
					this.loading = false;
					this.findCountry();
					this.validate(null);
				}
			}
		));
	}


	processPhoneNumber() {
		this.findCountry();
		this.onBlur();
	}

	onFocus() {
		this.focus.emit(this.value);
	}

	ngOnDestroy() {
		this.subscriptions.forEach(subscription => subscription.unsubscribe());
	}


	onBlur() {
		if (this.value && this.type && this.value !== this._validatedValue) {
			this.loading = true;
			this.validate(null);
			this.onChangeCallback(this.innerValue);
			this._validatedValue = this.value;
			this.subscriptions.push(this.phoneNumberLookupService.get(this.value).subscribe(
				response => {
					if (response.data.number === this.value) {
						this.phoneNumber = response.data;
						this.loading = false;
						this.validate(null);
						this.onChangeCallback(this.innerValue);
					}
				},
				error => {
					console.error(error);
					this.phoneNumber = new PhoneNumberLookupResult();
					this.loading = false;
					this.validate(null);
				}
			));
		}
		this.blur.emit(this.value);
	}

	findCountry() {
		if (this.innerValue == null) {
			this.setCountry(null);
		} else {
			let phonePrefix = this.innerValue.toString();
			if (this.country == null || !phonePrefix.startsWith(this.country.phonePrefix.toString())) {
				this.setCountry(null);
				if (phonePrefix.length > this.maxPhonePrefixLength) {
					phonePrefix = phonePrefix.substr(0, this.maxPhonePrefixLength);
				}
				while (phonePrefix.length > 0) {
					this.setCountry(this.countryByPhonePrefix.get(phonePrefix));
					if (this.country != null) {
						break;
					}
					if (phonePrefix.length === 1) {
						break;
					}
					phonePrefix = phonePrefix.substr(0, phonePrefix.length - 1);
				}
			}
			this.onChangeCallback(this.innerValue);
		}
	}

	setCountry(country: CountryLookupModel) {
		if (this.country !== country) {
			this.country = country;
			this.countrySelected.emit(country);
		}
	}




}
