<div class="adn-form-field"
	[ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
	<label>
		<span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass" aria-hidden="true">&nbsp;</span>{{label}}
		<ng-content select="app-text-label"></ng-content><span *ngIf="required">&nbsp;*</span>
	</label>
	<div class="adn-form-field-content">
		<mat-checkbox *ngFor="let option of allItems" class="adn-form-field-check-box" [ngModel]="getBit(value, option)"
			(change)="value = toggleBit(value, option);">{{toOptionText(option)}}</mat-checkbox>
	</div>
</div>
<div class="adn-form-field-hint" *ngIf="errorStateMatcher.valid && hint">{{hint}}</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
	<span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
		class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>