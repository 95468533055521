import { ScheduleSortProperty } from "app/generated/backend/shipment/api/schedule-sort-property";
import { Shipment } from "app/generated/backend/shipment/api/shipment";
import { ShipmentDetailModel } from "app/generated/backend/shipment/api/shipment-detail-model";
import { ShipmentLocation } from "app/generated/backend/shipment/api/shipment-location";
import { ShipmentParty } from "app/generated/backend/shipment/api/shipment-party";
import { LocalityLookupModel } from "app/generated/backend/types/locality-lookup-model";
import { TranshipmentType } from "app/generated/backend/types/transhipment-type";
import { ShippingMovementType } from "app/generated/backend/types/shipping-movement-type";

export class BookingState {
	shipment: Shipment;
	detail: ShipmentDetailModel;
	signedAt: string;
	signature: string;
	parties: ShipmentParty[];
	pickupLocation: ShipmentLocation;
	deliveryLocation: ShipmentLocation;
	pickupPartyPlaceId: number;
	deliveryPartyPlaceId: number;
	namedDestinationLocality: LocalityLookupModel;
	namedOriginLocality: LocalityLookupModel;
	filterCarrierPartyId: number;
	filterTranshipmentType: TranshipmentType;
	shippingMovementType: ShippingMovementType;
	limit: number;
	offset: number;
	sortProperty: ScheduleSortProperty;
}