import { Directive, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, ValidationErrors, Validator } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Observable, Subscription } from 'rxjs';

const noop = () => {
	// This is intentional
};

export interface BaseEnumClass<T> {
	transform(value: T, suffix: string): Observable<string>;
}

@Directive()
export class BaseEnumFlagCheckBoxComponent<T> implements ControlValueAccessor, Validator {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Output()
	itemSelected: EventEmitter<T> = new EventEmitter<T>();

	@Output() blur = new EventEmitter<number>();

	// public loading: boolean = true;
	private _innerValue: T;
	public model: string = '';
	// public allItems: T[];
	protected subscriptions = new Array<Subscription>();
	public errorStateMatcher = new SimpleErrorStateMatcher();
	public currentSelectedId = null;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): T {
		return this._innerValue;
	}

	// set accessor including call the onchange callback
	set value(id: T) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
			this.onChangeCallback(id);
		}
	}

	// From ControlValueAccessor interface
	writeValue(id: T) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		this.errorStateMatcher.valid = true;
		if (!this.value && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		return null;
	}

	toDisplayText(item: T): string {
		let displayText = this._textByValue.get(item);
		if (displayText) {
			return displayText;
		}
		return null;
	}

	toTitleText(item: T): string {
		return this.toDisplayText(item);
	}

	toOptionText(item: T): string {
		return this.toDisplayText(item);
	}

	getOptionValue(option: T): T {
		return option;
	}

	selectItem(option: T) {
		if (this.allItems) {
			this.value = option;
			this.model = null;
			this.allItems.forEach(eachItem => {
				if (eachItem === this.value) {
					this.model = this.toDisplayText(eachItem);
				}
			});
		}
		this.validate(null);
	}

	private _textByValue = new Map<T, string>();
	private _valueByText = new Map<string, T>();

	constructor(
		public allItems: T[],
		private source: BaseEnumClass<T>
	) {
		allItems.forEach(item => {
			this.source.transform(item, 'label').subscribe(text => {
				this._textByValue.set(item, text);
				this._valueByText.set(text, item);
			});
		});
	}


	getBit(value: number, bit: number): boolean {
		return (value & bit) !== 0;
	}

	toggleBit(value: number, bit: number): number {
		if (value & bit) {
			value &= ~bit;
		} else {
			value |= bit;
		}
		return value;
	}
}
