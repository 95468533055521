import { Injectable } from '@angular/core';
import { RailCarrierLookupModel } from 'app/generated/backend/rail/api/rail-carrier-lookup-model';
import { RailFclCarrierLookupHandlerService } from 'app/generated/backend/rail/service/rail-fcl-carrier-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CoreCacheService } from '../cache/CoreCacheService';

@Injectable()
export class CoreRailFclCarrierLookupService extends CachedEntityService<RailCarrierLookupModel> {
	constructor(
		service: RailFclCarrierLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(service, cacheService.getRailFclCarriers());
	}
	transformItem(item: RailCarrierLookupModel): RailCarrierLookupModel {
		if (item) {
			item.match = '';
			if (item.name) {
				item.match += item.name.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
