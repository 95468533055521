import { CommonModule } from '@angular/common';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse, ProperHttpParamEncoder } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { ShipmentDocument } from 'app/generated/backend/shipment/api/shipment-document';

@Injectable()
export class ShipmentDocumentHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	post(
		number: string,
		request: ShipmentDocument
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		return this.http.post<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/documents`, request).pipe(
			map(response => response),
			catchError(this.handleError));
	}
	delete(
		number: string,
		id: number
	): Observable<JsonResourceResponse<ShipmentDetailModel>> {
		let params = new HttpParams({ encoder: new ProperHttpParamEncoder() });
		if (id!==null && id!==undefined) {
			params = params.set('id', id.toString());
		}
		return this.http.delete<JsonResourceResponse<ShipmentDetailModel>>(`api/v1/shipment/shipments/${number}/documents`, { params }).pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		ShipmentDocumentHandlerService
	]
})
export class ShipmentDocumentHandlerModule { }
