import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum ShippingMovementType {
	None = 0,
	DoorToDoor = 1,
	DoorToPort = 2,
	PortToPort = 4,
	PortToDoor = 7,
}

@Pipe({
name: 'shippingMovementType'
})
export class ShippingMovementTypePipe extends BasePipe<ShippingMovementType> implements PipeTransform {
	static prefix = 'Backend.Types.ShippingMovementType.';
	static keys = [
		ShippingMovementType.None,
		ShippingMovementType.DoorToDoor,
		ShippingMovementType.DoorToPort,
		ShippingMovementType.PortToPort,
		ShippingMovementType.PortToDoor];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ShippingMovementType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ShippingMovementType.None: return 'None';
			case ShippingMovementType.DoorToDoor: return 'DoorToDoor';
			case ShippingMovementType.DoorToPort: return 'DoorToPort';
			case ShippingMovementType.PortToPort: return 'PortToPort';
			case ShippingMovementType.PortToDoor: return 'PortToDoor';
			default: return null;
		}
	}
	transform(value: ShippingMovementType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ShippingMovementTypePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(ShippingMovementTypePipe.keys, ShippingMovementTypePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ShippingMovementTypePipe
	],
	exports: [
		ShippingMovementTypePipe
	],
	providers: [
		ShippingMovementTypePipe
	]
})
export class ShippingMovementTypeModule { }
