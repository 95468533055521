import { Injectable } from '@angular/core';
import { SeaCarrierLookupModel } from 'app/generated/backend/sea/api/sea-carrier-lookup-model';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CoreCacheService } from '../cache/CoreCacheService';
import { SeaFclCarrierLookupHandlerService } from 'app/generated/backend/sea/service/sea-fcl-carrier-lookup-handler';

@Injectable()
export class CoreSeaFclCarrierLookupService extends CachedEntityService<SeaCarrierLookupModel> {
	constructor(
		service: SeaFclCarrierLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(service, cacheService.getSeaFclCarriers());
	}
	transformItem(item: SeaCarrierLookupModel): SeaCarrierLookupModel {
		if (item) {
			item.match = '';
			if (item.name) {
				item.match += item.name.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
