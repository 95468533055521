import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Injectable, NgModule } from '@angular/core';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { SeaCarrierLookupModel } from 'app/generated/backend/sea/api/sea-carrier-lookup-model';

@Injectable()
export class SeaLclCarrierLookupHandlerService {
	private handleError(error: Response | any) {
		return throwError(error);
	}
	constructor(private http: HttpClient) { }
	get(
	): Observable<JsonResourceResponse<SeaCarrierLookupModel[]>> {
		return this.http.get<JsonResourceResponse<SeaCarrierLookupModel[]>>('api/v1/sea/lclCarriers/lookup').pipe(
			map(response => response),
			catchError(this.handleError));
	}
}

@NgModule({
	imports: [
		CommonModule
	],
	providers: [
		SeaLclCarrierLookupHandlerService
	]
})
export class SeaLclCarrierLookupHandlerModule { }
