import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Feature } from 'app/generated/backend/types/feature';
import { PartyCategory } from 'app/generated/backend/types/party-category';
import { PermissionFlags } from 'app/generated/backend/types/permission-flags';
import { BehaviorSubject, Observable, throwError as observableThrowError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { AuthRequest } from '../../generated/backend/auth/api/auth-request';
import { AuthRequestCode } from '../../generated/backend/auth/api/auth-request-code';
import { AuthResponse } from '../../generated/backend/auth/api/auth-response';
import { AdminAccessControl } from '../../generated/backend/types/admin-access-control';
import { PartyAccessControl } from '../../generated/backend/types/party-access-control';
import { JsonResourceResponse } from '../json/json-resource-response';


@Injectable()
export class CoreAuthenticationService {
	private authUrl = 'api/v1/public/auth';  // URL to web api
	public authenticationChanged: BehaviorSubject<AuthResponse>;
	private partyReadRights: boolean[];
	private partyListRights: boolean[];
	private partyUpdateRights: boolean[];
	private adminReadRights: boolean[];
	private adminListRights: boolean[];
	private adminUpdateRights: boolean[];
	private authentication: AuthResponse = null;
	private _partyPermissions: PermissionFlags[];
	private _menuState: boolean;
	public get menuState(): boolean {
		return this._menuState;
	}
	public set menuState(value: boolean) {
		this._menuState = value;
	}
	constructor(
		private http: HttpClient
	) {
		this.authenticationChanged = new BehaviorSubject(null);
		this.clear();
	}
	isValidated() {
		return this.authentication != null;
	}
	clear() {
		this.partyReadRights = new Array<boolean>(PartyAccessControl.MaxAccessControl);
		this.partyListRights = new Array<boolean>(PartyAccessControl.MaxAccessControl);
		this.partyUpdateRights = new Array<boolean>(PartyAccessControl.MaxAccessControl);
		this.adminReadRights = new Array<boolean>(AdminAccessControl.MaxAccessControl);
		this.adminListRights = new Array<boolean>(AdminAccessControl.MaxAccessControl);
		this.adminUpdateRights = new Array<boolean>(AdminAccessControl.MaxAccessControl);
		this.authentication = null;
		this._partyPermissions = null;
		this.authenticationChanged.next(null);
	}
	hasFeature(features: Feature): boolean {
		return this.authentication?.party?.features?.find(f => f === features) === features;
	}
	hasPartyPermission(accessControl: PartyAccessControl, flags: PermissionFlags): boolean {
		return this._partyPermissions && this._partyPermissions.length > accessControl && (this._partyPermissions[accessControl] & flags) !== 0;
	}
	hasAdminPermission(control: AdminAccessControl, flag: PermissionFlags): boolean {
		if (!this.authentication || !this.authentication.adminPermissions) {
			return false;
		}
		if (this.authentication.adminPermissions.length <= control) {
			return false;
		}
		return (this.authentication.adminPermissions[control] & flag) !== 0;
	}
	getAuthentication(): AuthResponse {
		return this.authentication;
	}
	getAdminReadRights(): boolean[] {
		return this.adminReadRights;
	}
	getAdminListRights(): boolean[] {
		return this.adminListRights;
	}
	getAdminUpdateRights(): boolean[] {
		return this.adminUpdateRights;
	}
	getPartyReadRights(): boolean[] {
		return this.partyReadRights;
	}
	getPartyListRights(): boolean[] {
		return this.partyListRights;
	}
	getPartyUpdateRights(): boolean[] {
		return this.partyUpdateRights;
	}
	getPartyId(): number {
		return this.authentication && this.authentication.party ? this.authentication.party.id : null;
	}

	getPartyCountryId(): number {
		return this.authentication && this.authentication.party ? this.authentication.party.countryId : null;
	}

	getPartyName(): string {
		return this.authentication && this.authentication.party ? this.authentication.party.name : null;
	}

	getUserId(): number {
		return this.authentication && this.authentication.user ? this.authentication.user.id : null;
	}

	isAdmin(): boolean {
		if (this.authentication && this.authentication.adminPermissions && this.authentication.adminPermissions.length > 0) {
			return true;
		}
		return false;
	}
	isCarrier(): boolean {
		if (this.authentication && this.authentication.party && ((this.authentication.party.category & PartyCategory.Carrier) != 0)) {
			return true;
		}
		return false;
	}
	setMenuState(state: boolean) {
		this.menuState = state;
	}
	getMenuState() {
		return this.menuState;
	}
	private setAuthentication(authResponse: AuthResponse) {
		this._partyPermissions = authResponse && authResponse.party ? authResponse.party.permissions : null;
		for (let i = 0; i < PartyAccessControl.MaxAccessControl; ++i) {
			this.partyReadRights[i] =
				(authResponse != null && authResponse.party != null && authResponse.party.permissions != null &&
					authResponse.party.permissions.length > i && (authResponse.party.permissions[i] & PermissionFlags.Read) !== 0);
			this.partyListRights[i] =
				(authResponse != null && authResponse.party != null && authResponse.party.permissions != null &&
					authResponse.party.permissions.length > i && (authResponse.party.permissions[i] & PermissionFlags.List) !== 0);
			this.partyUpdateRights[i] =
				(authResponse != null && authResponse.party != null && authResponse.party.permissions != null &&
					authResponse.party.permissions.length > i && (authResponse.party.permissions[i] & PermissionFlags.Update) !== 0);
		}
		for (let i = 0; i < AdminAccessControl.MaxAccessControl; ++i) {
			this.adminReadRights[i] =
				(authResponse != null && authResponse.adminPermissions != null &&
					authResponse.adminPermissions.length > i && (authResponse.adminPermissions[i] & PermissionFlags.Read) !== 0);
			this.adminListRights[i] =
				(authResponse != null && authResponse.adminPermissions != null &&
					authResponse.adminPermissions.length > i && (authResponse.adminPermissions[i] & PermissionFlags.List) !== 0);
			this.adminUpdateRights[i] =
				(authResponse != null && authResponse.adminPermissions != null &&
					authResponse.adminPermissions.length > i && (authResponse.adminPermissions[i] & PermissionFlags.Update) !== 0);
		}
		this.authentication = authResponse;
		this.authenticationChanged.next(authResponse);
	}
	validate(): Observable<void> {
		const request = new AuthRequest();
		request.code = AuthRequestCode.Validate;
		request.referrer = document.referrer;
		return this.http.post<JsonResourceResponse<AuthResponse>>(this.authUrl, request).pipe(
			map(response => {
				this.setAuthentication(response.data);
			}),
			catchError(this.handleError));

	}
	switchParty(partyId: number): Observable<AuthResponse> {
		const request = new AuthRequest();
		request.code = AuthRequestCode.SwitchParty;
		request.partyId = partyId;
		return this.http.post<JsonResourceResponse<AuthResponse>>(this.authUrl, request).pipe(
			map(response => {
				const authResponse = response.data;
				this.setAuthentication(authResponse);
				if (!authResponse.user) {
					observableThrowError(new Error('Invalid party'));
				}
				return authResponse;
			}),
			catchError(this.handleError));
	}

	login(request: AuthRequest): Observable<AuthResponse> {
		request.code = AuthRequestCode.Login;
		return this.http.post<JsonResourceResponse<AuthResponse>>(this.authUrl, request).pipe(
			map(response => {
				const authResponse = response.data;
				this.setMenuState(true);
				this.setAuthentication(authResponse);
				if (!authResponse.user && !authResponse.challenge) {
					observableThrowError(new Error('Authentication failed'));
				}

				return authResponse;
			}),
			catchError(this.handleError));
	}

	logout() {
		return this.http.delete(this.authUrl).pipe(
			map(response => {
				this.setAuthentication(null);
			}),
			catchError(this.handleError));
	}

	private handleError(error: Response | any) {
		return observableThrowError(error);
	}
}
