import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum ScheduleSortProperty {
	None = 0,
	Cheapest = 1,
	Fastest = 2,
	Greenest = 3,
}

@Pipe({
name: 'scheduleSortProperty'
})
export class ScheduleSortPropertyPipe extends BasePipe<ScheduleSortProperty> implements PipeTransform {
	static prefix = 'Backend.Shipment.Api.ScheduleSortProperty.';
	static keys = [
		ScheduleSortProperty.None,
		ScheduleSortProperty.Cheapest,
		ScheduleSortProperty.Fastest,
		ScheduleSortProperty.Greenest];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: ScheduleSortProperty): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case ScheduleSortProperty.None: return 'None';
			case ScheduleSortProperty.Cheapest: return 'Cheapest';
			case ScheduleSortProperty.Fastest: return 'Fastest';
			case ScheduleSortProperty.Greenest: return 'Greenest';
			default: return null;
		}
	}
	transform(value: ScheduleSortProperty, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(ScheduleSortPropertyPipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		ScheduleSortPropertyPipe
	],
	exports: [
		ScheduleSortPropertyPipe
	],
	providers: [
		ScheduleSortPropertyPipe
	]
})
export class ScheduleSortPropertyModule { }
