import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { PlaceDetailHintComponent } from 'app/booking/cargo-groups/PlaceDetailHintComponent';
import { AddressLookupModule } from 'app/core/address/AddressLookupModule';
import { ContainerSizeInputModule } from 'app/core/container-size-input/ContainerSizeInputModule';
import { ContainerTypeInputModule } from 'app/core/container-type-input/ContainerTypeInputModule';
import { CoreDateTimeModule } from 'app/core/date-time/CoreDateTimeModule';
import { HelpPopupModule } from 'app/core/help-popup/help-popup.module';
import { CoreIncotermsModule } from 'app/core/incoterms/CoreIncotermsModule';
import { LoadingModule } from 'app/core/loading/LoadingModule';
import { CoreLocalityLookupModule } from 'app/core/locality/lookup/CoreLocalityLookupModule';
import { CoreNumberInputModule } from 'app/core/number/CoreNumberInputModule';
import { CorePackageTypeModule } from 'app/core/package-type/CorePackageTypeModule';
import { ContainerTypeModule } from 'app/generated/backend/types/container-type';
import { ShipmentPackagePropertiesModule } from 'app/generated/backend/types/shipment-package-properties';
import { PlacesDetailHandlerModule } from 'app/generated/backend/locality/service/places-detail-handler';
import { SetCargoGroupHandlerModule } from 'app/generated/backend/shipment/service/set-cargo-group-handler';
import { IncotermsDetailHandlerModule } from 'app/generated/backend/trade/service/incoterms-detail-handler';
import { PackageTypeDetailHandlerModule } from 'app/generated/backend/trade/service/package-type-detail-handler';
import { PartyPlaceModule } from 'app/party/place/PartyPlaceModule';
import { CoreButtonModule } from '../../core/button/CoreButtonModule';
import { CommonMaterialModule } from '../../core/common-material/core-common-material.module';
import { CoreHarmonizedCommodityModule } from '../../core/harmonized-commodity/CoreHarmonizedCommodityModule';
import { CoreMeasureModule } from '../../core/measure/CoreMeasureModule';
import { PanelModule } from '../../core/panel/PanelModule';
import { ContainerSizeModule } from '../../generated/backend/types/container-size';
import { ContainerLookupHandlerModule } from '../../generated/backend/shipment/service/container-lookup-handler';
import { BookingCargoGroupsComponent } from './BookingCargoGroupsComponent';
import { ShipmentCargoGroupsModule } from 'app/shipment/cargo-groups/ShipmentCargoGroupsModule';
import { CoreCarrierModule } from 'app/core/carrier-lookup/CoreCarrierModule';
import { TranshipmentTypeInputModule } from 'app/core/transhipment-type-input/TranshipmentTypeInputModule';
import { CoreSelectInputModule } from 'app/core/select-input/CoreSelectInputModule';
import { ScheduleSortPropertyModule } from 'app/generated/backend/shipment/api/schedule-sort-property';

@NgModule({
	imports: [
		AddressLookupModule,
		CommonMaterialModule,
		CommonModule,
		ContainerSizeInputModule,
		ContainerSizeModule,
		ContainerTypeInputModule,
		ContainerTypeModule,
		ContainerLookupHandlerModule,
		CoreButtonModule,
		CoreCarrierModule,
		CoreDateTimeModule,
		CoreHarmonizedCommodityModule,
		CoreIncotermsModule,
		CoreLocalityLookupModule,
		CoreMeasureModule,
		CoreNumberInputModule,
		CorePackageTypeModule,
		CoreSelectInputModule,
		FormsModule,
		HelpPopupModule,
		IncotermsDetailHandlerModule,
		LoadingModule,
		CoreNumberInputModule,
		PackageTypeDetailHandlerModule,
		PanelModule,
		PartyPlaceModule,
		PlacesDetailHandlerModule,
		ScheduleSortPropertyModule,
		SetCargoGroupHandlerModule,
		ShipmentPackagePropertiesModule,
		ShipmentCargoGroupsModule,
		TranshipmentTypeInputModule,
		TranslateModule,
	],
	declarations: [BookingCargoGroupsComponent, PlaceDetailHintComponent],
	exports: [
		BookingCargoGroupsComponent, PlaceDetailHintComponent
	]
})
export class BookingCargoGroupsModule { }
