import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { CarrierLookupHandlerModule } from 'app/generated/backend/party/service/carrier-lookup-handler';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { HelpPopupModule } from '../help-popup/help-popup.module';
import { CarrierLogoPipe } from "./CarrierLogoPipe";
import { CoreCarrierLookupComponent } from "./CoreCarrierLookupComponent";
import { CoreSeaFclCarrierLookupComponent } from "./CoreSeaFclCarrierLookupComponent";
import { CarrierNamePipe } from "./CarrierNamePipe";
import { CoreCarrierLookupService } from './CoreCarrierLookupService';
import { CoreSeaFclCarrierLookupService } from './CoreSeaFclCarrierLookupService';
import { CoreRailFclCarrierLookupService } from './CoreRailFclCarrierLookupService';
import { CoreBinaryContentModule } from '../binary-content/CoreBinaryContentModule';
import { SeaFclCarrierLookupHandlerModule } from 'app/generated/backend/sea/service/sea-fcl-carrier-lookup-handler';
import { RailFclCarrierLookupHandlerModule } from 'app/generated/backend/rail/service/rail-fcl-carrier-lookup-handler';
import { SeaLclCarrierLookupHandlerModule } from 'app/generated/backend/sea/service/sea-lcl-carrier-lookup-handler';
import { CoreSeaLclCarrierLookupComponent } from './CoreSeaLclCarrierLookupComponent';
import { CoreSeaLclCarrierLookupService } from './CoreSeaLclCarrierLookupService';
import { CoreSeaCarrierLookupComponent } from './CoreSeaCarrierLookupComponent';
import { CoreSeaCarrierLookupService } from './CoreSeaCarrierLookupService';
import { RailLclCarrierLookupHandlerModule } from 'app/generated/backend/rail/service/rail-lcl-carrier-lookup-handler';
import { CoreRailFclCarrierLookupComponent } from './CoreRailFclCarrierLookupComponent';
import { CoreRailLclCarrierLookupComponent } from './CoreRailLclCarrierLookupComponent';
import { CoreRailLclCarrierLookupService } from './CoreRailLclCarrierLookupService';
import { AutocompleteModule } from 'app/_templates/base-id-input-typeahead/autocomplete/AutocompleteModule';
import { SeaCarrierLookupHandlerModule } from 'app/generated/backend/sea/service/sea-carrier-lookup-handler';
import { CoreFclCarrierLookupComponent } from './CoreFclCarrierLookupComponent';
import { CoreFclCarrierLookupService } from './CoreFclCarrierLookupService';
import { FclCarrierLookupHandlerModule } from 'app/generated/backend/party/service/fcl-carrier-lookup-handler';
import { LclCarrierLookupHandlerModule } from 'app/generated/backend/party/service/lcl-carrier-lookup-handler';
import { CoreLclCarrierLookupService } from './CoreLclCarrierLookupService';
import { CoreLclCarrierLookupComponent } from './CoreLclCarrierLookupComponent';


@NgModule({
	imports: [
		AutocompleteModule,
		CarrierLookupHandlerModule,
		CommonMaterialModule,
		CommonModule,
		CoreBinaryContentModule,
		FormsModule,
		HelpPopupModule,
		FclCarrierLookupHandlerModule,
		LclCarrierLookupHandlerModule,
		RailFclCarrierLookupHandlerModule,
		RailLclCarrierLookupHandlerModule,
		SeaFclCarrierLookupHandlerModule,
		SeaLclCarrierLookupHandlerModule,
		SeaCarrierLookupHandlerModule,
		TranslateModule,
	],
	exports: [CoreCarrierLookupComponent, CoreFclCarrierLookupComponent, CoreLclCarrierLookupComponent,CoreSeaFclCarrierLookupComponent, CoreSeaLclCarrierLookupComponent, CoreSeaCarrierLookupComponent, CoreRailFclCarrierLookupComponent, CoreRailLclCarrierLookupComponent, CarrierNamePipe, CarrierLogoPipe],
	declarations: [CoreCarrierLookupComponent, CoreFclCarrierLookupComponent, CoreLclCarrierLookupComponent, CoreSeaFclCarrierLookupComponent, CoreSeaLclCarrierLookupComponent, CoreSeaCarrierLookupComponent, CoreRailFclCarrierLookupComponent, CoreRailLclCarrierLookupComponent, CarrierNamePipe, CarrierLogoPipe],
	providers: [CoreCarrierLookupService, CoreFclCarrierLookupService, CoreLclCarrierLookupService, CoreSeaFclCarrierLookupService, CoreSeaLclCarrierLookupService, CoreSeaCarrierLookupService, CoreRailFclCarrierLookupService, CoreRailLclCarrierLookupService, CarrierNamePipe]
})
export class CoreCarrierModule {
}
