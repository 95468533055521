import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CoreCacheService } from 'app/core/cache/CoreCacheService';
import { CoreCacheShipment } from 'app/core/cache/CoreCacheShipment';
import { CoreIncotermsLookupService } from 'app/core/incoterms/CoreIncotermsLookupService';
import { CoreTaskSummaryService } from 'app/core/task-summary/CoreTaskSummaryService';
import { CancelShipmentRequest } from 'app/generated/backend/shipment/api/cancel-shipment-request';
import { CancelShipmentResponse } from 'app/generated/backend/shipment/api/cancel-shipment-response';
import { ChangeShipmentTypeRequest } from 'app/generated/backend/shipment/api/change-shipment-type-request';
import { ChangeShipmentTypeResponse } from 'app/generated/backend/shipment/api/change-shipment-type-response';
import { CreateShipmentRequest } from 'app/generated/backend/shipment/api/create-shipment-request';
import { ExcludeShipmentServiceRequest } from 'app/generated/backend/shipment/api/exclude-shipment-service-request';
import { ExcludeShipmentServiceResponse } from 'app/generated/backend/shipment/api/exclude-shipment-service-response';
import { GetSchedulesRequest } from 'app/generated/backend/shipment/api/get-schedules-request';
import { GetSchedulesResponse } from 'app/generated/backend/shipment/api/get-schedules-response';
import { GetSuppliersRequest } from 'app/generated/backend/shipment/api/get-suppliers-request';
import { GetSuppliersResponse } from 'app/generated/backend/shipment/api/get-suppliers-response';
import { MoveCargoRequest } from 'app/generated/backend/shipment/api/move-cargo-request';
import { MoveContainerRequest } from 'app/generated/backend/shipment/api/move-container-request';
import { PatchShipmentRequest } from 'app/generated/backend/shipment/api/patch-shipment-request';
import { RejectShipmentServiceRequest } from 'app/generated/backend/shipment/api/reject-shipment-service-request';
import { RejectShipmentServiceResponse } from 'app/generated/backend/shipment/api/reject-shipment-service-response';
import { ResetShipmentServiceRequest } from 'app/generated/backend/shipment/api/reset-shipment-service-request';
import { ResetShipmentServiceResponse } from 'app/generated/backend/shipment/api/reset-shipment-service-response';
import { SetLocationRequest } from 'app/generated/backend/shipment/api/set-location-request';
import { SetLocationResponse } from 'app/generated/backend/shipment/api/set-location-response';
import { SetPartiesRequest } from 'app/generated/backend/shipment/api/set-parties-request';
import { SetScheduleRequest } from 'app/generated/backend/shipment/api/set-schedule-request';
import { SetScheduleResponse } from 'app/generated/backend/shipment/api/set-schedule-response';
import { SetSuppliersRequest } from 'app/generated/backend/shipment/api/set-suppliers-request';
import { SetSuppliersResponse } from 'app/generated/backend/shipment/api/set-suppliers-response';
import { Shipment } from 'app/generated/backend/shipment/api/shipment';
import { ShipmentBooking } from 'app/generated/backend/shipment/api/shipment-booking';
import { ShipmentCargoDetail } from 'app/generated/backend/shipment/api/shipment-cargo-detail';
import { ShipmentCargoGroup } from 'app/generated/backend/shipment/api/shipment-cargo-group';
import { ShipmentCargoModel } from 'app/generated/backend/shipment/api/shipment-cargo-model';
import { ShipmentCargoModifyRequest } from 'app/generated/backend/shipment/api/shipment-cargo-modify-request';
import { ShipmentContainer } from 'app/generated/backend/shipment/api/shipment-container';
import { ShipmentContainerOperationDetailPutResponse } from 'app/generated/backend/shipment/api/shipment-container-operation-detail-put-response';
import { ShipmentContainerOperationMasterPutResponse } from 'app/generated/backend/shipment/api/shipment-container-operation-master-put-response';
import { ShipmentContainerOperationTimeSlotModel } from 'app/generated/backend/shipment/api/shipment-container-operation-time-slot-model';
import { ShipmentDetailModel } from 'app/generated/backend/shipment/api/shipment-detail-model';
import { ShipmentDocument } from 'app/generated/backend/shipment/api/shipment-document';
import { ShipmentPackage } from 'app/generated/backend/shipment/api/shipment-package';
import { ShipmentPackingList } from 'app/generated/backend/shipment/api/shipment-packing-list';
import { ShipmentParty } from 'app/generated/backend/shipment/api/shipment-party';
import { ShipmentPartyModel } from 'app/generated/backend/shipment/api/shipment-party-model';
import { ShipmentPartyRateContractModel } from 'app/generated/backend/shipment/api/shipment-party-rate-contract-model';
import { ShipmentRelease } from 'app/generated/backend/shipment/api/shipment-release';
import { ShipmentService } from 'app/generated/backend/shipment/api/shipment-service';
import { ShipmentTaskResolveRequest } from 'app/generated/backend/shipment/api/shipment-task-resolve-request';
import { ShipmentTrackingEvent } from 'app/generated/backend/shipment/api/shipment-tracking-event';
import { ShipmentUserModel } from 'app/generated/backend/shipment/api/shipment-user-model';
import { ShipmentVesselModel } from 'app/generated/backend/shipment/api/shipment-vessel-model';
import { ShippingInstructionModel } from 'app/generated/backend/shipment/api/shipping-instruction-model';
import { VerifiedGrossMassRequestModel } from 'app/generated/backend/shipment/api/verified-gross-mass-request-model';
import { CancelShipmentHandlerService } from 'app/generated/backend/shipment/service/cancel-shipment-handler';
import { ChangeShipmentTypeHandlerService } from 'app/generated/backend/shipment/service/change-shipment-type-handler';
import { CreateShipmentHandlerService } from 'app/generated/backend/shipment/service/create-shipment-handler';
import { ExcludeShipmentServiceHandlerService } from 'app/generated/backend/shipment/service/exclude-shipment-service-handler';
import { GetSchedulesHandlerService } from 'app/generated/backend/shipment/service/get-schedules-handler';
import { GetSuppliersHandlerService } from 'app/generated/backend/shipment/service/get-suppliers-handler';
import { MoveCargoHandlerService } from 'app/generated/backend/shipment/service/move-cargo-handler';
import { MoveContainerHandlerService } from 'app/generated/backend/shipment/service/move-container-handler';
import { RejectShipmentServiceHandlerService } from 'app/generated/backend/shipment/service/reject-shipment-service-handler';
import { ResetShipmentServiceHandlerService } from 'app/generated/backend/shipment/service/reset-shipment-service-handler';
import { SetLocationHandlerService } from 'app/generated/backend/shipment/service/set-location-handler';
import { SetPartiesHandlerService } from 'app/generated/backend/shipment/service/set-parties-handler';
import { SetScheduleHandlerService } from 'app/generated/backend/shipment/service/set-schedule-handler';
import { SetSuppliersHandlerService } from 'app/generated/backend/shipment/service/set-suppliers-handler';
import { ShipmentBookingHandlerService } from 'app/generated/backend/shipment/service/shipment-booking-handler';
import { ShipmentCargoHandlerService } from 'app/generated/backend/shipment/service/shipment-cargo-handler';
import { ShipmentContainerHandlerService } from 'app/generated/backend/shipment/service/shipment-container-handler';
import { ShipmentContainerOperationTimeSlotHandlerService } from 'app/generated/backend/shipment/service/shipment-container-operation-time-slot-handler';
import { ShipmentDetailHandlerService } from 'app/generated/backend/shipment/service/shipment-detail-handler';
import { ShipmentDocumentHandlerService } from 'app/generated/backend/shipment/service/shipment-document-handler';
import { ShipmentPackageHandlerService } from 'app/generated/backend/shipment/service/shipment-package-handler';
import { ShipmentPackingListHandlerService } from 'app/generated/backend/shipment/service/shipment-packing-list-handler';
import { ShipmentRecalculateHandlerService } from 'app/generated/backend/shipment/service/shipment-recalculate-handler';
import { ShipmentReleaseHandlerService } from 'app/generated/backend/shipment/service/shipment-release-handler';
import { ShipmentTaskResolveHandlerService } from 'app/generated/backend/shipment/service/shipment-task-resolve-handler';
import { ShippingInstructionHandlerService } from 'app/generated/backend/shipment/service/shipping-instruction-handler';
import { VerifiedGrossMassHandlerService } from "app/generated/backend/shipment/service/verified-gross-mass-handler";
import { IncotermsLookupModel } from 'app/generated/backend/trade/api/incoterms-lookup-model';
import { Feature } from 'app/generated/backend/types/feature';
import { LocalityCategory } from 'app/generated/backend/types/locality-category';
import { LocalityLookupModel } from 'app/generated/backend/types/locality-lookup-model';
import { PartyTaskBlockReason } from 'app/generated/backend/types/party-task-block-reason';
import { ServiceCode } from 'app/generated/backend/types/service-code';
import { ShipmentLocationType } from 'app/generated/backend/types/shipment-location-type';
import { ShipmentType } from 'app/generated/backend/types/shipment-type';
import { JsonResourceResponse } from 'app/generated/generated-common';
import { BehaviorSubject, Observable, throwError as observableThrowError, of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


@Injectable()
export class ShipmentDetailService {

    public detailChanged = new BehaviorSubject<ShipmentDetailModel>(null);
    private _hasPurchaseOrdersFeature: boolean = false;
    private _hasReceivingFeature: boolean = false;
    private _hasCargoDetailsFeature: boolean = false;
    private _hasCargoDetailVolumeAndWeightFeature: boolean = false;
    private _hasCargoDetailValueFeature: boolean = false;
    private _hasShippingInstructions: boolean = false;
    private _hasPackingList: boolean = false;
    private _hasDeviceTracking: boolean = false;
    private _isSupplierLclParty: boolean = false;
    private _isSupplierFclParty: boolean = false;
    private _incotermsCache: IncotermsLookupModel;
    constructor(
        private service: ShipmentDetailHandlerService,
        private bookingService: ShipmentBookingHandlerService,
        private releaseService: ShipmentReleaseHandlerService,
        private getSchedulesService: GetSchedulesHandlerService,
        private setScheduleService: SetScheduleHandlerService,
        private getSuppliersService: GetSuppliersHandlerService,
        private setSuppliersService: SetSuppliersHandlerService,
        private setPartiesService: SetPartiesHandlerService,
        private cargoService: ShipmentCargoHandlerService,
        private taskResolveService: ShipmentTaskResolveHandlerService,
        private setLocationService: SetLocationHandlerService,
        private shipmentContainerService: ShipmentContainerHandlerService,
        private shipmentPackageService: ShipmentPackageHandlerService,
        private shipmentDocumentService: ShipmentDocumentHandlerService,
        private createShipmentService: CreateShipmentHandlerService,
        private rejectShipmentServiceService: RejectShipmentServiceHandlerService,
        private resetShipmentServiceService: ResetShipmentServiceHandlerService,
        private excludeShipmentServiceService: ExcludeShipmentServiceHandlerService,
        private cancelShipmentService: CancelShipmentHandlerService,
        private shipmentRecalculateService: ShipmentRecalculateHandlerService,
        private changeShipmentTypeService: ChangeShipmentTypeHandlerService,
        private moveCargoService: MoveCargoHandlerService,
        private moveContainerService: MoveContainerHandlerService,
        private shipmentContainerOperationTimeSlotHandlerService: ShipmentContainerOperationTimeSlotHandlerService,
        private cacheService: CoreCacheService,
        private taskSummaryService: CoreTaskSummaryService,
        private shippingInstructionService: ShippingInstructionHandlerService,
        private verifiedGrossMassService: VerifiedGrossMassHandlerService,
        private shipmentPackingListService: ShipmentPackingListHandlerService,
        private incotermsService: CoreIncotermsLookupService,
        private router: Router
    ) { }

    create(request: CreateShipmentRequest): Observable<ShipmentDetailModel> {

        return this.createShipmentService.post(request).pipe(
            map(response => this.setCache(response.data.detail)),
            catchError(this.handleError));
    }
    saveBooking(shipmentNumber: string, shipmentServiceId: number, booking: ShipmentBooking): Observable<ShipmentDetailModel> {
        return this.bookingService.put(shipmentNumber, shipmentServiceId, booking).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    saveShippingInstructions(shipmentNumber: string, id: number, shippingInstruction: ShippingInstructionModel): Observable<ShipmentDetailModel> {
        return this.shippingInstructionService.put(shipmentNumber, id, shippingInstruction).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    saveVerifiedGrossMass(shipmentNumber: string, id: number, request: VerifiedGrossMassRequestModel): Observable<ShipmentDetailModel> {
        return this.verifiedGrossMassService.put(shipmentNumber, id, request).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    savePackingList(shipmentNumber: string, request: ShipmentPackingList) {
        return this.shipmentPackingListService.post(shipmentNumber, request).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    deleteBooking(shipmentNumber: string, shipmentServiceId: number, bookingId: number): Observable<ShipmentDetailModel> {
        return this.bookingService.delete(shipmentNumber, shipmentServiceId, bookingId).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    moveCargo(request: MoveCargoRequest): Observable<ShipmentDetailModel> {
        return this.moveCargoService.post(request).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    moveContainer(request: MoveContainerRequest): Observable<ShipmentDetailModel> {
        return this.moveContainerService.post(request).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    saveContainer(shipmentNumber: string, container: ShipmentContainer): Observable<ShipmentDetailModel> {
        return this.shipmentContainerService.post(shipmentNumber, container).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    saveShipmentDetailModel(response: JsonResourceResponse<ShipmentContainerOperationMasterPutResponse> | JsonResourceResponse<ShipmentContainerOperationDetailPutResponse>) {
        if (response.data?.shipmentDetailModels?.length > 0) {
            this.setCache(response.data?.shipmentDetailModels[0]);
        }
        return response.data;
    }
    putShipmentOperationTimeSlot(shipmentNumber: string, partyPlacePointId: number, locationType: ShipmentLocationType, request: ShipmentContainerOperationTimeSlotModel) {
        return this.shipmentContainerOperationTimeSlotHandlerService.put(shipmentNumber, partyPlacePointId, locationType, request).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }

    deleteContainer(shipmentNumber: string, id: number): Observable<ShipmentDetailModel> {
        return this.shipmentContainerService.delete(shipmentNumber, id).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    savePackage(shipmentNumber: string, shipmentPackage: ShipmentPackage): Observable<ShipmentDetailModel> {

        return this.shipmentPackageService.post(shipmentNumber, shipmentPackage).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    deletePackage(shipmentNumber: string, id: number): Observable<ShipmentDetailModel> {
        return this.shipmentPackageService.delete(shipmentNumber, id).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    saveDocument(shipmentNumber: string, document: ShipmentDocument): Observable<ShipmentDetailModel> {
        return this.shipmentDocumentService.post(shipmentNumber, document).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    deleteCargo(shipmentNumber: string, id: number): Observable<ShipmentDetailModel> {
        return this.cargoService.delete(shipmentNumber, id).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    deleteDocument(shipmentNumber: string, id: number): Observable<ShipmentDetailModel> {

        return this.shipmentDocumentService.delete(shipmentNumber, id).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    saveRelease(shipmentNumber: string, shipmentServiceId: number, release: ShipmentRelease): Observable<ShipmentDetailModel> {
        return this.releaseService.put(shipmentNumber, shipmentServiceId, release).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }

    deleteRelease(shipmentNumber: string, shipmentServiceId: number, releaseId: number): Observable<ShipmentDetailModel> {
        return this.releaseService.delete(shipmentNumber, shipmentServiceId, releaseId).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    rejectService(request: RejectShipmentServiceRequest): Observable<RejectShipmentServiceResponse> {
        return this.rejectShipmentServiceService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    resetService(request: ResetShipmentServiceRequest): Observable<ResetShipmentServiceResponse> {
        return this.resetShipmentServiceService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    excludeService(request: ExcludeShipmentServiceRequest): Observable<ExcludeShipmentServiceResponse> {
        return this.excludeShipmentServiceService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    recalculate(shipmentNumber: string): Observable<ShipmentDetailModel> {
        return this.shipmentRecalculateService.get(shipmentNumber).pipe(
            map(response => {
                this.setCache(response.data);
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    cancel(request: CancelShipmentRequest): Observable<CancelShipmentResponse> {
        return this.cancelShipmentService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    changeShipmentType(request: ChangeShipmentTypeRequest): Observable<ChangeShipmentTypeResponse> {
        return this.changeShipmentTypeService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    setLocation(request: SetLocationRequest): Observable<SetLocationResponse> {
        return this.setLocationService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }));
    }
    getSuppliers(request: GetSuppliersRequest): Observable<GetSuppliersResponse> {
        return this.getSuppliersService.post(request).pipe(
            map(response => {
                return response.data;
            }
            ),
            catchError(this.handleError));
    }
    setSuppliers(request: SetSuppliersRequest): Observable<SetSuppliersResponse> {
        return this.setSuppliersService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }
            ));
    }
    getSchedules(request: GetSchedulesRequest): Observable<GetSchedulesResponse> {
        return this.getSchedulesService.post(request).pipe(
            map(response => {
                return response.data;
            }));
    }
    setSchedule(request: SetScheduleRequest): Observable<SetScheduleResponse> {
        return this.setScheduleService.post(request).pipe(
            map(response => {
                this.setCache(response.data.detail);
                return response.data;
            }));
    }
    setParties(shipmentNumber: string, shipmentVersion: number, parties: ShipmentParty[]): Observable<ShipmentDetailModel> {
        const request = new SetPartiesRequest();
        request.shipmentNumber = shipmentNumber;
        request.shipmentVersion = shipmentVersion;
        request.parties = parties;
        return this.setPartiesService.post(request).pipe(
            map(response => this.setCache(response.data.detail)));
    }
    getCargo(partyId: number, shipmentNumber: string, shipmentCargoDetailId: number): Observable<ShipmentCargoModel> {
        let params = new HttpParams();
        if (partyId) {
            params.set('partyId', partyId.toString());
        }
        if (shipmentCargoDetailId) {
            params.set('shipmentCargoDetailId', shipmentCargoDetailId.toString());
        }
        if (shipmentNumber) {
            params.set('shipmentNumber', shipmentNumber);
        }
        return this.cargoService.get(partyId, shipmentCargoDetailId, shipmentNumber).pipe(
            map(response => {
                this.setCache(response.data.shipment);
                return response.data
            }));
    }
    saveCargo(shipmentNumber: string, cargo: ShipmentCargoDetail): Observable<ShipmentDetailModel> {
        return this.cargoService.post(shipmentNumber, cargo).pipe(
            map(response => this.setCache(response.data)));
    }
    patchCargo(shipmentNumber: string, modifyRequest: ShipmentCargoModifyRequest): Observable<ShipmentDetailModel> {
        return this.cargoService.patch(shipmentNumber, modifyRequest).pipe(
            map(response => this.setCache(response.data)));
    }
    canResolveTask(shipmentNumber: string, taskId: number, request: ShipmentTaskResolveRequest): Observable<JsonResourceResponse<PartyTaskBlockReason>> {
        return null;
    }
    resolveTask(shipmentNumber: string, taskId: number, request: ShipmentTaskResolveRequest): Observable<ShipmentDetailModel> {
        return this.taskResolveService.put(shipmentNumber, taskId, request).pipe(
            map(response => this.setCache(response.data)));
    }
    getParty(id: number): ShipmentPartyModel {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.partyById) {
            return null;
        }
        return cache.partyById.get(id);
    }
    getService(id: number): ShipmentService {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.serviceById) {
            return null;
        }
        return cache.serviceById.get(id);
    }
    getUser(id: number): ShipmentUserModel {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.userById) {
            return null;
        }
        return cache.userById.get(id);
    }

    getContainer(id: number): ShipmentContainer {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.containerById) {
            return null;
        }
        return cache.containerById.get(id);
    }

    getContainers(): Map<number, ShipmentContainer> {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.containerById) {
            return null;
        }
        return cache.containerById
    }

    getVessel(id: number): ShipmentVesselModel {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.vesselById) {
            return null;
        }
        return cache.vesselById.get(id);
    }
    getLocality(id: number, category: LocalityCategory): LocalityLookupModel {
        const cache = this.cacheService.getShipmentCache();
        if (!cache.localityByKey) {
            return null;
        }
        return cache.localityByKey.get(id + ';' + category);
    }
    get(number: string, refresh = false): Observable<ShipmentDetailModel> {
        if (!refresh) {
            const cache = this.cacheService.getShipmentCache();
            if (cache.shipment && cache.shipment.shipment.number === number) {
                return of(cache.shipment);
            }
        }
        return this.service.get(number).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }
    patch(number: string, request: PatchShipmentRequest): Observable<ShipmentDetailModel> {
        const cache = this.cacheService.getShipmentCache();
        if (cache.shipment && cache.shipment.shipment.number !== number) {
            catchError(this.handleError);
        }
        return this.service.patch(number, request).pipe(
            map(response => this.setCache(response.data)),
            catchError(this.handleError));
    }

    getServiceOrder(service: ServiceCode) {
        switch (service) {
            case ServiceCode.Pickup: return 1;
            case ServiceCode.ExportClearance: return 2;
            case ServiceCode.OriginHandling: return 3;
            case ServiceCode.Carriage: return 4;
            case ServiceCode.DestinationHandling: return 5;
            case ServiceCode.ImportClearance: return 6;
            case ServiceCode.Delivery: return 7;
            default:
                return service;
        }
    }

    hasCargoFeature(): boolean {
        return this._hasCargoDetailsFeature
            || this._hasPurchaseOrdersFeature || this._hasReceivingFeature;
    }

    hasPackingList(): boolean {
        return this._hasPackingList;
    }

    hasDeviceTracking(): boolean {
        return this._hasDeviceTracking;
    }

    hasCargoValueFeature() {
        return this._hasCargoDetailValueFeature;
    }

    hasPurchaseOrdersFeature() {
        return this._hasPurchaseOrdersFeature;
    }

    hasCargoDetailWeightAndVolume(): boolean {
        return this._hasCargoDetailVolumeAndWeightFeature;
    }

    isSupplierLclParty(): boolean {
        return this._isSupplierLclParty;
    }
    isSupplierFclParty(): boolean {
        return this._isSupplierFclParty;
    }
    hasShippingInstructions(): boolean {
        return this._hasShippingInstructions;
    }

    getShipmentIncotermsCache(): IncotermsLookupModel {
        return this._incotermsCache;
    }

    setShipmentIncotermsCache(id: number) {
        if (!this._incotermsCache || this._incotermsCache?.id !== id) {
            this.incotermsService.getItems().subscribe(items => {
                items.forEach(item => {
                    if (item.id === id) {
                        this._incotermsCache = item;
                    }
                });
            });
        }
    }

    setCache(detail: ShipmentDetailModel): ShipmentDetailModel {
        const cache = this.cacheService.getShipmentCache();
        cache.shipment = detail;
        cache.userById = new Map<number, ShipmentUserModel>();
        cache.partyById = new Map<number, ShipmentPartyModel>();
        cache.serviceById = new Map<number, ShipmentService>();
        cache.containerById = new Map<number, ShipmentContainer>();
        cache.vesselById = new Map<number, ShipmentVesselModel>();
        cache.localityByKey = new Map<string, LocalityLookupModel>();
        cache.partyRateContractById = new Map<number, ShipmentPartyRateContractModel>();
        this._hasPurchaseOrdersFeature = false;
        this._hasReceivingFeature = false;
        this._hasCargoDetailsFeature = false;
        this._hasCargoDetailVolumeAndWeightFeature = false;
        this._hasCargoDetailValueFeature = false;
        this._hasPackingList = false;
        this._hasDeviceTracking = false;
        this._isSupplierLclParty = false;
        this._isSupplierFclParty = false;
        this._hasShippingInstructions = false;
        if (detail && detail.shipment) {
            this._isSupplierLclParty = detail.shipment.type === ShipmentType.Lcl;
            this._isSupplierFclParty = detail.shipment.type === ShipmentType.Fcl;
            const shipment = detail.shipment;
            if (detail.users) {
                detail.users.forEach(user => {
                    cache.userById.set(user.id, user);
                });
            }
            if (detail.parties) {
                detail.parties.forEach(party => {
                    cache.partyById.set(party.id, party);
                });
            }
            if (detail.vessels) {
                detail.vessels.forEach(vessel => {
                    cache.vesselById.set(vessel.id, vessel);
                });
            }
            detail.localities?.forEach(locality => {
                cache.localityByKey.set(locality.id + ';' + locality.category, locality);
            });
            detail.trackingVoyages?.forEach(tv => {
                if (tv.vesselId) {
                    tv.vessel = cache.vesselById.get(tv.vesselId);
                }
                if (tv.arrivalLocalityId) {
                    tv.arrivalLocality = cache.getLocality(tv.arrivalLocalityId, tv.arrivalLocalityCategory);
                }
                if (tv.departureLocalityId) {
                    tv.departureLocality = cache.getLocality(tv.departureLocalityId, tv.departureLocalityCategory);
                }
            });
            detail.partyRateContracts?.forEach(partyRateContract => {
                cache.partyRateContractById.set(partyRateContract.id, partyRateContract);
            });
            if (shipment) {
                const shipmentBookingById = new Map<number, ShipmentBooking>();
                const shipmentReleaseById = new Map<number, ShipmentRelease>();
                const cargoGropById = new Map<number, ShipmentCargoGroup>();
                this.setCargoGroups(shipment, cargoGropById);
                const packageById = new Map<number, ShipmentPackage>();
                this.setServices(shipment, shipmentBookingById, shipmentReleaseById, cache);
                this.setContainers(shipment, cargoGropById, shipmentBookingById, shipmentReleaseById, cache);
                this.setPackages(shipment, packageById, cargoGropById, shipmentBookingById, shipmentReleaseById);
                if (shipment.cargoDetails) {
                    shipment.cargoDetails.forEach(cargoDetail => {
                        cargoDetail.container = cache.containerById.get(cargoDetail.shipmentContainerId);
                        cargoDetail.package = packageById.get(cargoDetail.shipmentPackageId);
                    });
                }
                if (shipment.packages) {
                    shipment.packages.forEach(shipmentPackage => {
                        shipmentPackage.container = cache.containerById.get(shipmentPackage.shipmentContainerId);
                    });
                }
                this.setShipmentParties(shipment);
                this.setShipmentIncotermsCache(shipment.incotermsId);
            }
        }
        this.taskSummaryService.refresh();
        this.detailChanged.next(detail);
        if (detail && !detail.shipment) {
            this.router.navigate(['shipment', 'accessDenied'], { queryParams: { sn: detail.shipmentNumber } });
        }
        return detail;
    }
    private setShipmentParties(shipment: Shipment) {
        if (shipment.parties) {
            shipment.parties.forEach(party => {
                this.setFeatures(party);
            });
        }
    }

    private setFeatures(party: ShipmentParty) {
        party.features?.forEach(feature => {
            switch (feature) {
                case Feature.PurchaseOrders:
                    this._hasPurchaseOrdersFeature = true;
                    break;
                case Feature.Receiving:
                    this._hasReceivingFeature = true;
                    break;
                case Feature.CargoDetails:
                    this._hasCargoDetailsFeature = true;
                    break;
                case Feature.CargoDetailVolumeAndWeight:
                    this._hasCargoDetailVolumeAndWeightFeature = true;
                    break;
                case Feature.CargoDetailValue:
                    this._hasCargoDetailValueFeature = true;
                    break;
                case Feature.PackingList:
                    this._hasPackingList = true;
                    break;
                case Feature.DeviceTracking:
                    this._hasDeviceTracking = true;
                    break;
                case Feature.DirectCarrierBooking:
                    this._hasShippingInstructions = true;
                    break;
                default: // Do nothing
                    break;
            }
        });
        const partyPermissions = this.cacheService.getPartyPermissions();
    }

    private setPackages(shipment: Shipment, packageById: Map<number, ShipmentPackage>, cargoGropById: Map<number, ShipmentCargoGroup>, shipmentBookingById: Map<number, ShipmentBooking>, shipmentReleaseById: Map<number, ShipmentRelease>) {
        if (shipment.packages) {
            shipment.packages.forEach(shipmentPackage => {
                this.setPackage(shipmentPackage, packageById, cargoGropById, shipmentBookingById, shipmentReleaseById);
            });
        }
    }

    private setPackage(shipmentPackage: ShipmentPackage, packageById: Map<number, ShipmentPackage>, cargoGropById: Map<number, ShipmentCargoGroup>, shipmentBookingById: Map<number, ShipmentBooking>, shipmentReleaseById: Map<number, ShipmentRelease>) {
        shipmentPackage.match = '';
        if (shipmentPackage.number) {
            shipmentPackage.match += shipmentPackage.number.toLowerCase();
        }
        packageById.set(shipmentPackage.id, shipmentPackage);
        const cargoGroup = cargoGropById.get(shipmentPackage.shipmentCargoGroupId);
        if (cargoGroup) {
            cargoGroup.shipmentPackages.push(shipmentPackage);
        }
        packageById.set(shipmentPackage.id, shipmentPackage);
        if (shipmentPackage.shipmentBookingId) {
            const shipmentBooking = shipmentBookingById.get(shipmentPackage.shipmentBookingId);
            if (shipmentBooking) {
                if (!shipmentBooking.packages) {
                    shipmentBooking.packages = new Array<ShipmentPackage>();
                }
                shipmentBooking.packages.push(shipmentPackage);
            }
        }
        if (shipmentPackage.shipmentReleaseId) {
            const shipmentRelease = shipmentReleaseById.get(shipmentPackage.shipmentReleaseId);
            if (shipmentRelease) {
                if (!shipmentRelease.packages) {
                    shipmentRelease.packages = new Array<ShipmentPackage>();
                }
                shipmentRelease.packages.push(shipmentPackage);
            }
        }
    }

    private setContainers(shipment: Shipment, cargoGropById: Map<number, ShipmentCargoGroup>, shipmentBookingById: Map<number, ShipmentBooking>, shipmentReleaseById: Map<number, ShipmentRelease>, cache: CoreCacheShipment) {
        if (shipment.containers) {
            shipment.containers.forEach(container => {
                this.setContainer(container, cargoGropById, shipment, shipmentBookingById, shipmentReleaseById, cache);
            });
        }
    }

    private setContainer(container: ShipmentContainer, cargoGropById: Map<number, ShipmentCargoGroup>, shipment: Shipment,
        shipmentBookingById: Map<number, ShipmentBooking>,
        shipmentReleaseById: Map<number, ShipmentRelease>, cache: CoreCacheShipment

    ) {
        cache.containerById.set(container.id, container);
        const cargoGroup = cargoGropById.get(container.shipmentCargoGroupId);
        if (cargoGroup) {
            cargoGroup.containers.push(container);
        }
        if (shipment.arrivalLocationId) {
            container.expectedArrivalLocation = cache.getLocality(shipment.arrivalLocationId, LocalityCategory.Location);
        }
        if (container.estimatedArrivalLocationId) {
            container.estimatedArrivalLocation = cache.getLocality(container.estimatedArrivalLocationId, LocalityCategory.Location);
        }
        if (container.events) {
            container.events.forEach(evt => {
                this.getLocation(evt, cache);
                evt.vessel = cache.vesselById.get(evt.vesselId);
            });
        }
        container.match = '';
        if (container.reference) {
            container.match += container.reference.toLowerCase();
        }
        if (container.number) {
            container.match += ' ' + container.number.toLowerCase();
        }
        this.addContainerToShipmentBookingAndRelease(container, shipmentBookingById, shipmentReleaseById);
    }

    private addContainerToShipmentBookingAndRelease(container: ShipmentContainer, shipmentBookingById: Map<number, ShipmentBooking>, shipmentReleaseById: Map<number, ShipmentRelease>) {
        if (container.shipmentBookingId) {
            const shipmentBooking = shipmentBookingById.get(container.shipmentBookingId);
            if (shipmentBooking) {
                if (!shipmentBooking.containers) {
                    shipmentBooking.containers = new Array<ShipmentContainer>();
                }
                shipmentBooking.containers.push(container);
            }
        }
        if (container.shipmentReleaseId) {
            const shipmentRelease = shipmentReleaseById.get(container.shipmentReleaseId);
            if (shipmentRelease) {
                if (!shipmentRelease.containers) {
                    shipmentRelease.containers = new Array<ShipmentContainer>();
                }
                shipmentRelease.containers.push(container);
            }
        }
    }

    private setServices(shipment: Shipment, shipmentBookingById: Map<number, ShipmentBooking>, shipmentReleaseById: Map<number, ShipmentRelease>, cache: CoreCacheShipment) {
        if (shipment.services) {
            shipment.services.sort((a, b) => {
                const asc = this.getServiceOrder(a.serviceCode);
                const bsc = this.getServiceOrder(b.serviceCode);
                if (asc < bsc) { return -1; }
                if (asc > bsc) { return 1; }
                return 0;
            });
            shipment.services.forEach(service => {
                cache.serviceById.set(service.id, service);
                if (service.partyRateContractId) {
                    service.partyRateContract = cache.partyRateContractById.get(service.partyRateContractId);
                }
                this.setService(service, cache);
                this.setServiceBookings(service, shipment, shipmentBookingById, cache);
                this.setServiceReleases(service, shipmentReleaseById, cache);
                this.setServiceVoyage(service, cache);
                this.setShippingInstructions(service, cache);
            });
        }
    }

    private setService(service: ShipmentService, cache: CoreCacheShipment) {
        if (service.delivery && service.delivery.expectedArrivalLocationId) {
            service.delivery.expectedArrivalLocation = cache.getLocality(service.delivery.expectedArrivalLocationId, LocalityCategory.Location);
        }
    }

    private setShippingInstructions(service: ShipmentService, cache: CoreCacheShipment) {
        if (service.instructions) {
            service.instructions.forEach(instruction => {
                this.setShippingInstructionVoyages(instruction, cache);
                if (instruction.billOfLadingReleaseLocationId) {
                    instruction.billOfLadingReleaseLocation = cache.getLocality(instruction.billOfLadingReleaseLocationId, LocalityCategory.Location);
                }
                if (instruction.placeOfPaymentLocationId) {
                    instruction.placeOfPaymentLocation = cache.getLocality(instruction.placeOfPaymentLocationId, LocalityCategory.Location);
                }
            });
        }
    }

    private setShippingInstructionVoyages(instruction: ShippingInstructionModel, cache: CoreCacheShipment) {
        if (instruction.voyages) {
            instruction.voyages.forEach(voyage => {
                if (voyage.arrivalLocalityId) {
                    voyage.arrivalLocality = cache.getLocality(voyage.arrivalLocalityId, voyage.arrivalLocalityCategory);
                }
                if (voyage.departureLocalityId) {
                    voyage.departureLocality = cache.getLocality(voyage.departureLocalityId, voyage.departureLocalityCategory);
                }
                if (voyage.vesselId) {
                    voyage.vessel = cache.vesselById.get(voyage.vesselId);
                }
            });
        }
    }

    private setServiceVoyage(service: ShipmentService, cache: CoreCacheShipment) {
        if (service.voyages) {
            service.voyages.forEach(voyage => {
                if (voyage.arrivalLocalityId) {
                    voyage.arrivalLocality = cache.getLocality(voyage.arrivalLocalityId, voyage.arrivalLocalityCategory);
                }
                if (voyage.departureLocalityId) {
                    voyage.departureLocality = cache.getLocality(voyage.departureLocalityId, voyage.departureLocalityCategory);
                }
                if (voyage.vesselId) {
                    voyage.vessel = cache.vesselById.get(voyage.vesselId);
                }
            });
        }
    }

    private setServiceReleases(service: ShipmentService, shipmentReleaseById: Map<number, ShipmentRelease>, cache: CoreCacheShipment) {
        if (service.releases) {
            service.releases.forEach(release => {
                shipmentReleaseById.set(release.id, release);
                if (release.voyages) {
                    release.voyages.forEach(releaseVoyage => {
                        if (releaseVoyage.arrivalLocalityId) {
                            releaseVoyage.arrivalLocality = cache.getLocality(releaseVoyage.arrivalLocalityId, releaseVoyage.arrivalLocalityCategory);
                        }
                        if (releaseVoyage.departureLocalityId) {
                            releaseVoyage.departureLocality = cache.getLocality(releaseVoyage.departureLocalityId, releaseVoyage.departureLocalityCategory);
                        }
                        if (releaseVoyage.vesselId) {
                            releaseVoyage.vessel = cache.vesselById.get(releaseVoyage.vesselId);
                        }
                    });
                }
            });
        }
    }

    private setServiceBookings(service: ShipmentService, shipment: Shipment, shipmentBookingById: Map<number, ShipmentBooking>, cache: CoreCacheShipment) {
        if (service.bookings) {
            service.bookings.forEach(booking => {
                this.setServiceBooking(shipment, booking, shipmentBookingById, cache);
            });
        }
    }

    private setServiceBooking(shipment: Shipment, booking: ShipmentBooking, shipmentBookingById: Map<number, ShipmentBooking>, cache: CoreCacheShipment) {
        if (shipment.arrivalLocationId) {
            booking.expectedArrivalLocation = cache.getLocality(shipment.arrivalLocationId, LocalityCategory.Location);
        }
        if (booking.estimatedArrivalLocationId) {
            booking.estimatedArrivalLocation = cache.getLocality(booking.estimatedArrivalLocationId, LocalityCategory.Location);
        }
        if (booking.events) {
            booking.events.forEach(evt => {
                this.getLocation(evt, cache);
                evt.vessel = cache.vesselById.get(evt.vesselId);
            });
        }
        shipmentBookingById.set(booking.id, booking);
        if (booking.voyages) {
            booking.voyages.forEach(bookingVoyage => {
                if (bookingVoyage.arrivalLocalityId) {
                    bookingVoyage.arrivalLocality = cache.getLocality(bookingVoyage.arrivalLocalityId, bookingVoyage.arrivalLocalityCategory);
                }
                if (bookingVoyage.departureLocalityId) {
                    bookingVoyage.departureLocality = cache.getLocality(bookingVoyage.departureLocalityId, bookingVoyage.departureLocalityCategory);
                }
                if (bookingVoyage.vesselId) {
                    bookingVoyage.vessel = cache.vesselById.get(bookingVoyage.vesselId);
                }
            });
        }
    }

    private getLocation(evt: ShipmentTrackingEvent, cache: CoreCacheShipment) {
        if (evt.locationId) {
            evt.location = cache.getLocality(evt.locationId, LocalityCategory.Location);
        }
    }

    private setCargoGroups(shipment: Shipment, cargoGropById: Map<number, ShipmentCargoGroup>) {
        if (shipment.cargoGroups) {
            shipment.cargoGroups.forEach(cargoGroup => {
                cargoGroup.containers = new Array<ShipmentContainer>();
                cargoGroup.shipmentPackages = new Array<ShipmentPackage>();
                cargoGropById.set(cargoGroup.id, cargoGroup);
            });
        }
    }

    private handleError(error: Response | any) {
        return observableThrowError(error);
    }

}
