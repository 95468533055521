import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { TaskMasterModel } from 'app/generated/backend/task/api/task-master-model';
import { ObjectClass } from 'app/generated/backend/types/object-class';
import { PartyTaskType } from 'app/generated/backend/types/party-task-type';
import { ShipmentContainerOperationType } from 'app/generated/backend/types/shipment-container-operation-type';

@Injectable()
export class CoreNavigationService {
	constructor(
		private router: Router
	) {
	}

	public navigateUserWelcome() {
		this.router.navigate(['/user/welcome']);
	}
	public navigateUserSettings() {
		this.router.navigate(['/user/settings']);
	}
	public navigateUserNotifications() {
		this.router.navigate(['/user/notifications']);
	}
	public navigateUserConsents() {
		this.router.navigate(['/user/consents']);
	}
	public navigateUserParties() {
		this.router.navigate(['/user/parties']);
	}
	public navigateUserRegisterParty() {
		this.router.navigate(['/user/parties/register']);
	}
	public navigatePartyDashboard() {
		this.router.navigate(['/party/dashboard']);
	}
	public navigatePartyProfile(isWelcome: boolean) {
		this.router.navigate(['/party/profile'], { queryParams: { welcome: isWelcome } });
	}
	public navigatePartyUser() {
		this.router.navigate(['/party/users']);
	}
	public navigatePartyParty() {
		this.router.navigate(['/party/parties']);
	}
	public navigatePartyLocation() {
		this.router.navigate(['/party/locations']);
	}
	public navigatePartyPlace() {
		this.router.navigate(['/party/places']);
	}
	public navigatePartyPlaceDetail(id: any) {
		this.router.navigate(['/party/places', id]);
	}
	public navigateShipmentCreate() {
		this.router.navigate(['booking']);
	}
	public navigateShipment(shipmentNumber: string) {
		this.router.navigate(['shipment', 'serviceDetails'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateCustomerAcceptCharges(shipmentNumber: string) {
		this.router.navigate(['shipment', 'acceptCharges'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentCargoCreate(shipmentNumber: string, shipmentContainerId: number, shipmentPackageId: number = null) {
		if (shipmentContainerId) {
			this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber, sc: shipmentContainerId } });
		} else if (shipmentPackageId) {
			this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber, sp: shipmentPackageId } });
		} else {
			this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber } });
		}
	}
	public navigateShipmentCargoDetail(shipmentNumber: string, id: number, shipmentContainerId: number, shipmentPackageId: number) {
		if (shipmentContainerId) {
			this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber, id: id, sc: shipmentContainerId } });
		} else if (shipmentPackageId) {
			this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber, id: id, sp: shipmentPackageId } });
		} else {
			this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber, id: id } });
		}
	}
	public navigateCompanyInfo(companyId: number) {
		this.router.navigate(['admin', 'crm', 'companies', companyId, 'info']);
	}
	public navigateCompanyCredit(companyId: number) {
		this.router.navigate(['admin', 'crm', 'companies', companyId, 'credit']);
	}
	public navigateAdvancedCargo(shipmentNumber: string, poNumber: string, soNumber: string) {
		this.router.navigate(['party', 'shipmentCargo'], { queryParams: { sn: shipmentNumber, poNumber: poNumber, soNumber: soNumber } });
	}
	public navigateShipmentCargoDuplicate(shipmentNumber: string, id: number) {
		this.router.navigate(['shipment', 'cargo', 'detail'], { queryParams: { sn: shipmentNumber, copyId: id } });
	}
	public navigateShipmentCargoMaster(shipmentNumber: string) {
		this.router.navigate(['shipment', 'cargo', 'master'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentContainerInfo(shipmentNumber: string, shipmentContainerId: number) {
		this.router.navigate(['shipment', 'container', 'detail', 'info'], { queryParams: { sn: shipmentNumber, sc: shipmentContainerId } });
	}
	public navigateShipmentContainerTracking(shipmentNumber: string, shipmentContainerId: number) {
		this.router.navigate(['shipment', 'container', 'detail', 'tracking'], { queryParams: { sn: shipmentNumber, sc: shipmentContainerId } });
	}
	public navigateShipmentBookingTracking(shipmentNumber: string, shipmentServiceId: number, shipmentBookingId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'detail', 'tracking'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, id: shipmentBookingId } });
	}
	public navigateShippingInstruction(shipmentNumber: string, shipmentServiceId: number, shipmentBookingId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'detail', 'shippingInstructions'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, id: shipmentBookingId } });
	}
	public navigateVerifiedGrossMass(shipmentNumber: string, shipmentServiceId: number, shipmentBookingId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'detail', 'verifiedGrossMass'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, id: shipmentBookingId } });
	}
	public navigateShipmentBooking(shipmentNumber: string, shipmentServiceId: number, shipmentBookingId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'detail', 'info'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, id: shipmentBookingId } });
	}
	public navigateShipmentPackingListMaster(shipmentNumber: string) {
		this.router.navigate(['shipment', 'packingList', 'master'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentPackingListDetail(shipmentNumber: string, shipmentPackingListNumber: number) {
		this.router.navigate(['shipment', 'packingList', 'detail'], { queryParams: { sn: shipmentNumber, spl: shipmentPackingListNumber } });
	}
	public navigateShipmentContainerMaster(shipmentNumber: string) {
		this.router.navigate(['shipment', 'container', 'master'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentContainerDetail(shipmentNumber: string, shipmentContainerId: number) {
		this.router.navigate(['shipment', 'container', 'detail', 'info'], { queryParams: { sn: shipmentNumber, sc: shipmentContainerId } });
	}
	public navigateShipmentContainerDetailCargo(shipmentNumber: string, shipmentContainerId: number) {
		this.router.navigate(['shipment', 'container', 'detail', 'cargo'], { queryParams: { sn: shipmentNumber, sc: shipmentContainerId } });
	}
	public navigateShipmentOverview(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'overview'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentPackageInfo(shipmentNumber: string, shipmentPackageId: number, number: string, sc: number, hc: string) {
		if (sc) {
			this.router.navigate(['shipment', 'package', 'detail', 'info'], { queryParams: { sn: shipmentNumber, sp: shipmentPackageId, number: number, sc: sc, hc: hc } });
		}
		else {
			this.router.navigate(['shipment', 'package', 'detail', 'info'], { queryParams: { sn: shipmentNumber, sp: shipmentPackageId, number: number, hc: hc } });
		}
	}
	public navigateShipmentPackageTracking(shipmentNumber: string, shipmentPackageId: number) {
		this.router.navigate(['shipment', 'package', 'detail', 'tracking'], { queryParams: { sn: shipmentNumber, sc: shipmentPackageId } });
	}
	public navigateShipmentPackageMaster(shipmentNumber: string) {
		this.router.navigate(['shipment', 'package', 'master'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentPackageDetailCargo(shipmentNumber: string, shipmentPackageId: number) {
		this.router.navigate(['shipment', 'package', 'detail', 'cargo'], { queryParams: { sn: shipmentNumber, sp: shipmentPackageId } });
	}
	public navigateShipmentDocumentCreate(shipmentNumber: string) {
		this.router.navigate(['shipment', 'document', 'detail'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentDocumentDetail(shipmentNumber: string, id: number) {
		this.router.navigate(['shipment', 'document', 'detail'], { queryParams: { sn: shipmentNumber, id: id } });
	}
	public navigateShipmentDocumentMaster(shipmentNumber: string) {
		this.router.navigate(['shipment', 'document', 'master'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentSchedule(shipmentNumber: string) {
		this.router.navigate(['shipment', 'schedule'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentLocationPickup(shipmentNumber: string) {
		this.router.navigate(['shipment', 'location'], { queryParams: { sn: shipmentNumber, lt: 'pickup' } });
	}
	public navigateShipmentLocationDelivery(shipmentNumber: string) {
		this.router.navigate(['shipment', 'location'], { queryParams: { sn: shipmentNumber, lt: 'delivery' } });
	}
	public navigateShipmentPickupMaster(shipmentNumber: string) {
		this.router.navigate(['shipment', 'service', 'pickup', 'master'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentDeliveryPlanning(shipmentNumber: string, taskId: number, ssid: number) {
		this.router.navigate(['shipment', 'deliveryPlanning'], { queryParams: { sn: shipmentNumber, lt: 'delivery', ssid, taskId } });
	}
	public navigateShipmentDeliveryService(shipmentNumber: string, ssid: number) {
		this.router.navigate(['shipment', 'service', 'deliveries', 'master'], { queryParams: { sn: shipmentNumber, ssid: ssid } });
	}
	public navigateShipmentConfirmDeliveryPlanning(shipmentNumber: string, taskId: number, ssid: number) {
		this.router.navigate(['shipment', 'service', 'deliveries', 'master'], { queryParams: { sn: shipmentNumber, ssid, taskId } });
	}
	public navigateShipmentParties(shipmentNumber: string) {
		this.router.navigate(['shipment', 'parties'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateShipmentServiceConfirmAta(shipmentNumber: string, shipmentServiceId: number, type: PartyTaskType) {
		this.router.navigate(['shipment', 'service', 'overview'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, type: type } });
	}
	public navigateShipmentService(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'overview'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentServiceBookingsMaster(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'master'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentServiceBookingsCreate(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'detail', 'info'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentServiceBookingsDetail(shipmentNumber: string, shipmentServiceId: number, bookingId: number) {
		this.router.navigate(['shipment', 'service', 'bookings', 'detail', 'info'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, id: bookingId } });
	}
	public navigateShipmentServiceReleasesMaster(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'releases', 'master'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentServiceReleasesCreate(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'releases', 'detail'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentServiceReleasesDetail(shipmentNumber: string, shipmentServiceId: number, releaseId: number) {
		this.router.navigate(['shipment', 'service', 'releases', 'detail'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId, id: releaseId } });
	}
	public navigateShipmentServiceSchedule(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'schedule'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentServiceSuppliers(shipmentNumber: string, shipmentServiceId: number) {
		this.router.navigate(['shipment', 'service', 'suppliers'], { queryParams: { sn: shipmentNumber, ssid: shipmentServiceId } });
	}
	public navigateShipmentSuppliers(shipmentNumber: string) {
		this.router.navigate(['shipment', 'suppliers'], { queryParams: { sn: shipmentNumber } });
	}
	public navigatePurchaseOrderMaster() {
		this.router.navigate(['party', 'purchaseOrders', 'master']);
	}
	public navigatePurchaseOrderItemMaster(poNumber: string, number: number) {
		this.router.navigate(['party', 'purchaseOrderItems', 'master'], { queryParams: { purchaseOrderNumber: poNumber, number: number } });
	}
	public navigatePurchaseOrderDetail(purchaseOrderId: number) {
		this.router.navigate(['party', 'purchaseOrders', 'detail'], { queryParams: { id: purchaseOrderId } });
	}
	public navigatePurchaseOrderItemDetail(purchaseOrderId: number, purchaseOrderItemId: number, nextNumber: number = null, isDuplicate = null, deliveryAt: string = null, plannedArrivalWarehouseAt: string = null) {
		this.router.navigate(['party', 'purchaseOrders', 'detail', 'item'], { queryParams: { purchaseOrderId: purchaseOrderId, id: purchaseOrderItemId, itemNumber: nextNumber, isDuplicate: isDuplicate, deliveryAt: deliveryAt, plannedArrivalWarehouseAt: plannedArrivalWarehouseAt } });
	}
	public navigateSalesOrderMaster() {
		this.router.navigate(['party', 'salesOrders', 'master']);
	}
	public navigateSalesOrderDetail(salesOrderId: number) {
		this.router.navigate(['party', 'salesOrders', 'detail'], { queryParams: { id: salesOrderId } });
	}
	public navigateSalesOrderItemMaster(salesOrderNumber: string, number: number) {
		this.router.navigate(['party', 'salesOrderItems', 'master'], { queryParams: { salesOrderNumber: salesOrderNumber, number: number } });
	}
	public navigateSalesOrderItemDetail(salesOrderId: number, salesOrderItemId: number, nextNumber: number = null, isDuplicate = null, deliveryAt: string = null, incotermsId: number = null) {
		this.router.navigate(['party', 'salesOrders', 'detail', 'item'], { queryParams: { salesOrderId: salesOrderId, id: salesOrderItemId, itemNumber: nextNumber, isDuplicate: isDuplicate, deliveryAt: deliveryAt, incotermsId: incotermsId } });
	}
	public navigateGoodsReceiptMaster() {
		this.router.navigate(['party', 'goodsReceipts', 'master']);
	}
	public navigateGoodsReceiptCreate(shipmentNumber: string) {
		this.router.navigate(['party', 'goodsReceipts', 'detail'], { queryParams: { sn: shipmentNumber } });
	}
	public navigateGoodsReceiptDetail(goodsReceiptId: number) {
		this.router.navigate(['party', 'goodsReceipts', 'detail'], { queryParams: { id: goodsReceiptId } });
	}
	public navigateGoodsReceiptItemDetail(goodsReceiptId: number, goodsReceiptItemId: number) {
		this.router.navigate(['party', 'goodsReceipts', 'detail', 'item'], { queryParams: { grid: goodsReceiptId, id: goodsReceiptItemId } });
	}
	public navigatePartyPlaceOperationDetail(partyPlaceId: number, shipmentNumber: string, containerId: number, shipmentContainerOperationType: ShipmentContainerOperationType) {
		this.router.navigate(['party', 'places', partyPlaceId, 'operations', 'detail'], { queryParams: { id: partyPlaceId, sn: shipmentNumber, cid: containerId, oType: shipmentContainerOperationType } });
	}
	public navigatePartyPlaceOperations(partyPlaceId: number) {
		this.router.navigate(['party', 'places', partyPlaceId, 'operations']);
	}
	public navigatePartyPlaceSchedule(partyPlaceId: number) {
		this.router.navigate(['party', 'places', partyPlaceId, 'schedule']);
	}
	public navigateContentDetail(contentId: number) {
		this.router.navigate(['admin', 'cms', 'content', contentId]);
	}
	public navigateTopicDetail(topicId: number) {
		this.router.navigate(['admin', 'cms', 'topic', topicId]);
	}
	public navigateMediaDetail(mediaId: number) {
		this.router.navigate(['admin', 'cms', 'media', mediaId]);
	}
	public navigateTemplateDetail(templateId: number) {
		this.router.navigate(['admin', 'cms', 'templates', templateId]);
	}
	public navigateGlossaryMaster() {
		this.router.navigate(['glossary']);
	}
	public navigateGlossaryDetail(uri: string) {
		this.router.navigate(['glossary', uri]);
	}
	public navigatePartyTask(item: TaskMasterModel) {
		switch (item.type) {
			case PartyTaskType.CustomerAcceptRole:
			case PartyTaskType.BookerSelectParty:
				this.navigateShipmentParties(item.objectNumber);
				break;
			case PartyTaskType.CustomerAcceptCharges:
				this.navigateCustomerAcceptCharges(item.objectNumber);
				break;
			case PartyTaskType.SupplierBookCarrier:
			case PartyTaskType.CustomerAcceptBooking:
				this.navigateShipmentServiceBookingsMaster(item.objectNumber, item.secondObjectId);
				break;
			case PartyTaskType.SupplierReleaseCarrier:
				this.navigateShipmentServiceReleasesMaster(item.objectNumber, item.secondObjectId);
				break;
			case PartyTaskType.CustomerSelectPickupLocation:
				this.navigateShipmentLocationPickup(item.objectNumber);
				break;
			case PartyTaskType.CustomerSelectDeliveryLocation:
				this.navigateShipmentLocationDelivery(item.objectNumber);
				break;
			case PartyTaskType.SenderAddCargoDetails:
			case PartyTaskType.SellerAddCargoValue:
				this.navigateShipmentCargoMaster(item.objectNumber);
				break;
			case PartyTaskType.SenderAddContainers:
				this.navigateShipmentContainerMaster(item.objectNumber);
				break;
			case PartyTaskType.SenderAddPackages:
				this.navigateShipmentPackageMaster(item.objectNumber);
				break;
			case PartyTaskType.CustomerBookShipment:
				this.navigateShipmentSchedule(item.objectNumber);
				break;
			case PartyTaskType.SenderCloseContainers:
				this.navigateShipmentContainerDetail(item.objectNumber, item.secondObjectId);
				break;
			case PartyTaskType.ReceiverAcceptDeliveryPlan:
				this.navigateShipmentDeliveryPlanning(item.objectNumber, item.id, item.secondObjectId);
				break;
			case PartyTaskType.SupplierConfirmDeliveryPlan:
				this.navigateShipmentDeliveryService(item.objectNumber, item.id);
				break;
			case PartyTaskType.SupplierSetActualTimeOfArrival:
				this.navigateShipmentServiceConfirmAta(item.objectNumber, item.secondObjectId, item.type);
				break;
			case PartyTaskType.UpdateCreditRating:
				this.navigateCompanyCredit(item.objectId);
				break;
			default:
				this.navigateClassObject(item.objectClass, item.objectNumber, item.objectId, item.secondObjectClass, item.secondObjectId, item.thirdObjectClass, item.thirdObjectId);
				break;
		}
	}
	navigateShipmentObject(objectNumber: string, objectId: number, secondObjectClass: ObjectClass, secondObjectId: number, thirdObjectClass: ObjectClass, thirdObjectId: number) {
		switch (secondObjectClass) {
			case ObjectClass.ShipmentService:
				this.navigateShipmentService(objectNumber, secondObjectId);
				break;
			default:
				this.navigateShipment(objectNumber);
				break;
		}
	}
	navigateClassObject(objectClass: ObjectClass, objectNumber: string, objectId: number, secondObjectClass: ObjectClass, secondObjectId: number, thirdObjectClass: ObjectClass, thirdObjectId: number) {
		switch (objectClass) {
			case ObjectClass.Shipment:
				this.navigateShipmentObject(objectNumber, objectId, secondObjectClass, secondObjectId, thirdObjectClass, thirdObjectId);
				break;
			case ObjectClass.PurchaseOrder:
				this.navigatePurchaseOrderDetail(objectId);
				break;
			case ObjectClass.SalesOrder:
				this.navigateSalesOrderDetail(objectId);
				break;
			default:
				console.error('dont know how to navigate', objectClass);
				break;
		}
	}
}
