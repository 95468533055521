<div class="app-panel" [ngClass]="{ 'narrow-panel': panelWidth === 1, 
                'balance-panel': panelWidth === 2, 
                'medium-panel': panelWidth === 3, 
                'stretch-panel': panelWidth === 4, 
                'wide-panel': panelWidth === 5, 
                'centered-maxwidth-container': middle }">

  <div class="app-panel-header">
    <div class="form-group row adn-panel-title" *ngIf="title">
      <div class="adn-panel-title-inner">
        <h1>
          <span class="state-block" *ngIf="state">{{state}}&nbsp;</span>
          <span *ngIf="parentTitle" class="d-none d-sm-inline-block">{{parentTitle}}&nbsp;&gt;&nbsp;</span>
          <span>{{title}}</span>
          <span *ngIf="titleId">&nbsp;{{titleId}}</span>
        </h1>
      </div>
      <ng-content select="header"></ng-content>
    </div>

    <div *ngIf="content?.body" [innerHTML]="contentBodySafeHtml || content.body"></div>
  </div>



  <app-loading [loading]="loading"></app-loading>

  <div class="table-responsive" *ngIf="!loading && errors && errors.length > 0">
    <table class="table table-striped table-bordered table-sm">
      <thead>
        <tr class="alert adn-color-background-alert-brighter">
          <th colspan="5">{{'nav.errorDetails' | translate}}</th>
        </tr>
        <tr>
          <th>Row</th>
          <th>Column</th>
          <th>Title</th>
          <th>Detail</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let error of errors">
          <td>{{error.source?.row}}</td>
          <td>{{error.source?.column}}</td>
          <td>{{error.title}}</td>
          <td>{{error.detail}}</td>
        </tr>
      </tbody>
    </table>
  </div>

  <ng-content *ngIf="!loading"></ng-content>

  <br />

</div>