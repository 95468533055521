import { Component, forwardRef, Input } from '@angular/core';
import { NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BaseEnumFlagCheckBoxComponent } from 'app/_templates/BaseEnumFlagCheckBoxComponent';
import { TranshipmentType, TranshipmentTypePipe } from 'app/generated/backend/types/transhipment-type';

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => TranshipmentTypeFlagCheckBoxComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => TranshipmentTypeFlagCheckBoxComponent),
	multi: true
};
@Component({
	selector: 'app-transhipment-type-flag-check-box',
	templateUrl: '../../_templates/BaseEnumFlagCheckBoxComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class TranshipmentTypeFlagCheckBoxComponent extends BaseEnumFlagCheckBoxComponent<TranshipmentType> {

	@Input()
	labelKey: string;

	@Input()
	helpUri: string;

	constructor(
		transhipmentTypePipe: TranshipmentTypePipe) {
		super([TranshipmentType.Direct, TranshipmentType.Single, TranshipmentType.Double, TranshipmentType.Multiple], transhipmentTypePipe);
	}


}
