<div class="adn-form-field"
        [ngClass]="{'adn-form-field-invalid': !errorStateMatcher.valid, 'adn-form-field-disabled': disabled}">
        <label>
                <span *ngIf="iconClass" class="fal fa-fw" [ngClass]="iconClass"
                        aria-hidden="true">&nbsp;</span>{{label}}
                <ng-content select="app-text-label"></ng-content><span *ngIf="required">&nbsp;*</span>
        </label>
        <div class="adn-form-field-content">
                <input class="input-style" [ngClass]="{'text-right':rightAlign}" [placeholder]="placeholder"
                        [(ngModel)]="model" (keydown)="onKeyDownEvent($event)" [appAutocomplete]="autocomplete"
                        autocomplete="none" [required]="required" [disabled]="disabled">
                <div class="input-icon" *ngIf="!required && !disabled">
                        <button mat-button mat-icon-button matSuffix *ngIf="value || allowZeroValue && value === 0"
                                type="button" (click)="onClear()" title="{{ 'field.clear.label' | translate}}"
                                tabindex="-1">
                                <span class="fal fa-fw fa-times-circle"></span>
                        </button>
                </div>
                <app-help-popup-button class="input-icon" *ngIf="helpUri" [uri]="helpUri"></app-help-popup-button>
        </div>
</div>
<div class="adn-form-field-hint" *ngIf="errorStateMatcher.valid && hint">{{hint}}</div>
<div class="adn-form-field-error" *ngIf="!errorStateMatcher.valid">
        <span class="error-icon fal fa-fw fa-exclamation-circle"></span>&nbsp;<span
                class="error-text">{{errorStateMatcher.errorKey | translate}}</span>
</div>

<app-autocomplete #autocomplete="appAutocomplete" (selectItem)="onItemSelected($event)">
        <ng-template appAutocompleteContent>
                <ng-container *ngIf="allItems">
                        <app-option *ngFor="let option of allItems" [value]="option.value" id="{{option.value}}"
                                title="{{option.hint}}"
                                [ngClass]="{'adn-dropdwon-active': option.value === currentSelectedId, 'adn-dropdwon-inactive': option.value !== currentSelectedId}">
                                {{option.label}}
                        </app-option>
                        <app-option class="no-result" *ngIf="!allItems.length">{{'field.noResults.label' | translate}}
                        </app-option>
                </ng-container>
        </ng-template>
</app-autocomplete>