import { Injectable } from '@angular/core';
import { RailCarrierLookupModel } from 'app/generated/backend/rail/api/rail-carrier-lookup-model';
import { RailLclCarrierLookupHandlerService } from 'app/generated/backend/rail/service/rail-lcl-carrier-lookup-handler';
import { CachedEntityService } from 'app/_templates/CachedEntityService';
import { CoreCacheService } from '../cache/CoreCacheService';

@Injectable()
export class CoreRailLclCarrierLookupService extends CachedEntityService<RailCarrierLookupModel> {
	constructor(
		service: RailLclCarrierLookupHandlerService,
		cacheService: CoreCacheService
	) {
		super(service, cacheService.getRailLclCarriers());
	}
	transformItem(item: RailCarrierLookupModel): RailCarrierLookupModel {
		if (item) {
			item.match = '';
			if (item.name) {
				item.match += item.name.toLowerCase();
			}
			if (item.name) {
				item.match += ' ' + item.name.toLowerCase();
			}
		}
		return item;
	}
}
