import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
import { of } from 'rxjs';
export enum PartyTaskType {
	None = 0,
	SupplierAcceptService = 1,
	SupplierConfirmDelivery = 2,
	SupplierBookCarrier = 3,
	CustomerAcceptRole = 4,
	SupplierReleaseCarrier = 5,
	CustomerAcceptCharges = 6,
	CustomerSelectSupplier = 7,
	CustomerSelectPickupLocation = 8,
	CustomerSelectDeliveryLocation = 9,
	BookerSelectParty = 10,
	SenderAddCargoDetails = 11,
	CustomerBookShipment = 12,
	SellerAddCargoValue = 13,
	SenderAddContainers = 14,
	SupplierSetActualTimeOfDeparture = 15,
	SenderAddPackages = 16,
	CustomerAcceptBooking = 17,
	SenderCloseContainers = 18,
	ReceiverAcceptDeliveryPlan = 19,
	SupplierConfirmDeliveryPlan = 20,
	SupplierSetActualTimeOfArrival = 21,
	UpdateCreditRating = 22,
	BuyerDispatchPurchaseOrder = 23,
	SenderCreateShipment = 24,
	SenderSubmitShippingInstructions = 25,
	SenderSubmitVerifiedGrossMass = 26,
	InsertTrackingInfo = 27,
	ProvideContactDelivery = 28,
	UploadMasterBl = 29,
	UploadHouseBl = 30,
	UploadCommercialInvoice = 31,
	UploadPackingList = 32,
}

@Pipe({
name: 'partyTaskType'
})
export class PartyTaskTypePipe extends BasePipe<PartyTaskType> implements PipeTransform {
	static prefix = 'Backend.Types.PartyTaskType.';
	static keys = [
		PartyTaskType.None,
		PartyTaskType.SupplierAcceptService,
		PartyTaskType.SupplierConfirmDelivery,
		PartyTaskType.SupplierBookCarrier,
		PartyTaskType.CustomerAcceptRole,
		PartyTaskType.SupplierReleaseCarrier,
		PartyTaskType.CustomerAcceptCharges,
		PartyTaskType.CustomerSelectSupplier,
		PartyTaskType.CustomerSelectPickupLocation,
		PartyTaskType.CustomerSelectDeliveryLocation,
		PartyTaskType.BookerSelectParty,
		PartyTaskType.SenderAddCargoDetails,
		PartyTaskType.CustomerBookShipment,
		PartyTaskType.SellerAddCargoValue,
		PartyTaskType.SenderAddContainers,
		PartyTaskType.SupplierSetActualTimeOfDeparture,
		PartyTaskType.SenderAddPackages,
		PartyTaskType.CustomerAcceptBooking,
		PartyTaskType.SenderCloseContainers,
		PartyTaskType.ReceiverAcceptDeliveryPlan,
		PartyTaskType.SupplierConfirmDeliveryPlan,
		PartyTaskType.SupplierSetActualTimeOfArrival,
		PartyTaskType.UpdateCreditRating,
		PartyTaskType.BuyerDispatchPurchaseOrder,
		PartyTaskType.SenderCreateShipment,
		PartyTaskType.SenderSubmitShippingInstructions,
		PartyTaskType.SenderSubmitVerifiedGrossMass,
		PartyTaskType.InsertTrackingInfo,
		PartyTaskType.ProvideContactDelivery,
		PartyTaskType.UploadMasterBl,
		PartyTaskType.UploadHouseBl,
		PartyTaskType.UploadCommercialInvoice,
		PartyTaskType.UploadPackingList];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: PartyTaskType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case PartyTaskType.None: return 'None';
			case PartyTaskType.SupplierAcceptService: return 'SupplierAcceptService';
			case PartyTaskType.SupplierConfirmDelivery: return 'SupplierConfirmDelivery';
			case PartyTaskType.SupplierBookCarrier: return 'SupplierBookCarrier';
			case PartyTaskType.CustomerAcceptRole: return 'CustomerAcceptRole';
			case PartyTaskType.SupplierReleaseCarrier: return 'SupplierReleaseCarrier';
			case PartyTaskType.CustomerAcceptCharges: return 'CustomerAcceptCharges';
			case PartyTaskType.CustomerSelectSupplier: return 'CustomerSelectSupplier';
			case PartyTaskType.CustomerSelectPickupLocation: return 'CustomerSelectPickupLocation';
			case PartyTaskType.CustomerSelectDeliveryLocation: return 'CustomerSelectDeliveryLocation';
			case PartyTaskType.BookerSelectParty: return 'BookerSelectParty';
			case PartyTaskType.SenderAddCargoDetails: return 'SenderAddCargoDetails';
			case PartyTaskType.CustomerBookShipment: return 'CustomerBookShipment';
			case PartyTaskType.SellerAddCargoValue: return 'SellerAddCargoValue';
			case PartyTaskType.SenderAddContainers: return 'SenderAddContainers';
			case PartyTaskType.SupplierSetActualTimeOfDeparture: return 'SupplierSetActualTimeOfDeparture';
			case PartyTaskType.SenderAddPackages: return 'SenderAddPackages';
			case PartyTaskType.CustomerAcceptBooking: return 'CustomerAcceptBooking';
			case PartyTaskType.SenderCloseContainers: return 'SenderCloseContainers';
			case PartyTaskType.ReceiverAcceptDeliveryPlan: return 'ReceiverAcceptDeliveryPlan';
			case PartyTaskType.SupplierConfirmDeliveryPlan: return 'SupplierConfirmDeliveryPlan';
			case PartyTaskType.SupplierSetActualTimeOfArrival: return 'SupplierSetActualTimeOfArrival';
			case PartyTaskType.UpdateCreditRating: return 'UpdateCreditRating';
			case PartyTaskType.BuyerDispatchPurchaseOrder: return 'BuyerDispatchPurchaseOrder';
			case PartyTaskType.SenderCreateShipment: return 'SenderCreateShipment';
			case PartyTaskType.SenderSubmitShippingInstructions: return 'SenderSubmitShippingInstructions';
			case PartyTaskType.SenderSubmitVerifiedGrossMass: return 'SenderSubmitVerifiedGrossMass';
			case PartyTaskType.InsertTrackingInfo: return 'InsertTrackingInfo';
			case PartyTaskType.ProvideContactDelivery: return 'ProvideContactDelivery';
			case PartyTaskType.UploadMasterBl: return 'UploadMasterBl';
			case PartyTaskType.UploadHouseBl: return 'UploadHouseBl';
			case PartyTaskType.UploadCommercialInvoice: return 'UploadCommercialInvoice';
			case PartyTaskType.UploadPackingList: return 'UploadPackingList';
			default: return null;
		}
	}
	transform(value: PartyTaskType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(PartyTaskTypePipe.prefix + str, suffix, interpolateParams);
		}
		return of(null);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		PartyTaskTypePipe
	],
	exports: [
		PartyTaskTypePipe
	],
	providers: [
		PartyTaskTypePipe
	]
})
export class PartyTaskTypeModule { }
