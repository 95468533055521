import { AfterViewInit, Component, ContentChildren, EventEmitter, forwardRef, Input, Output, QueryList } from '@angular/core';
import { ControlValueAccessor, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from '@angular/forms';
import { SimpleErrorStateMatcher } from 'app/_templates/simple-error-state-matcher';
import { Subscription } from 'rxjs';
import { CoreSelectOptionComponent } from './CoreSelectOptionComponent';

const noop = () => {
	// This is intentional
};

export const CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR: any = {
	provide: NG_VALUE_ACCESSOR,
	useExisting: forwardRef(() => CoreSelectInputComponent),
	multi: true
};

export const CUSTOM_INPUT_CONTROL_VALIDATORS: any = {
	provide: NG_VALIDATORS,
	useExisting: forwardRef(() => CoreSelectInputComponent),
	multi: true
};

@Component({
	selector: 'app-select-input',
	templateUrl: './CoreSelectInputComponent.html',
	providers: [CUSTOM_INPUT_CONTROL_VALUE_ACCESSOR, CUSTOM_INPUT_CONTROL_VALIDATORS]
})
export class CoreSelectInputComponent implements ControlValueAccessor, Validator, AfterViewInit {

	@Input()
	public required: boolean = false;

	@Input()
	public disabled: boolean = false;

	@Input()
	public addNew: boolean = false;

	@Input()
	public maxOptions: number;

	@Input()
	public label: string;

	@Input()
	public iconClass: string;

	@Input()
	public placeholder: string = '';

	@Input()
	public helpUri: string;

	@Input()
	public hint: string;

	@Input()
	public allowZeroValue: boolean = false;

	@Input()
	public rightAlign: boolean = false;

	@Output()
	itemSelected: EventEmitter<CoreSelectOptionComponent> = new EventEmitter<CoreSelectOptionComponent>();

	// public loading: boolean = true;
	private _innerValue: number;
	public model: string = '';
	public allItems: CoreSelectOptionComponent[];
	protected subscriptions = new Array<Subscription>();
	public errorStateMatcher = new SimpleErrorStateMatcher();

	@ContentChildren(CoreSelectOptionComponent) options: QueryList<CoreSelectOptionComponent>;

	private currentIndex = null;
	public currentSelectedId = null;

	// Placeholders for the callbacks which are later providesd
	// by the Control Value Accessor
	private onTouchedCallback: () => void = noop;
	private onChangeCallback: (_: any) => void = noop;

	// get accessor
	get value(): number {
		return this._innerValue;
	};

	// set accessor including call the onchange callback
	set value(id: number) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
			this.onChangeCallback(id);
		}
	}

	// From ControlValueAccessor interface
	writeValue(id: number) {
		if (id !== this._innerValue) {
			this._innerValue = id;
			this.selectItem(id);
		}
	}

	// From ControlValueAccessor interface
	registerOnChange(fn: any) {
		this.onChangeCallback = fn;
	}

	// From ControlValueAccessor interface
	registerOnTouched(fn: any) {
		this.onTouchedCallback = fn;
	}

	public validate(c: UntypedFormControl): ValidationErrors | null {
		this.errorStateMatcher.valid = true;
		if (this.value == null && this.required) {
			this.errorStateMatcher.valid = false;
			this.errorStateMatcher.errorKey = 'error.required';
			return {
				required: true
			};
		}
		return null;
	}

	toDisplayText(item: CoreSelectOptionComponent): string {
		if (!item) {
			return null;
		}
		return item.label;
	}

	onItemSelected(value: any) {
		this.selectItem(value);
		this.allItems.forEach(eachItem => {
			if (eachItem.value === value) {
				this.itemSelected.emit(eachItem);
			}
		});

	}

	selectItem(id: number) {
		if (id !== null && this.allItems) {
			if (this._innerValue !== id) {
				this._innerValue = id;
				this.onChangeCallback(id);
			}
			this.allItems.forEach(eachItem => {
				if (eachItem.value === this.value) {
					this.model = this.toDisplayText(eachItem);
				}
			});
		} else {
			this.model = null;
		}
		this.validate(null);
	}

	constructor(
	) { }

	updateItems() {
		const items = [];
		this.options.forEach(option => {
			let item = new CoreSelectOptionComponent();
			item.label = option.label;
			item.value = option.value;
			item.hint = option.hint;
			items.push(item);
		});
		this.allItems = items;
	}

	ngAfterViewInit() {
		this.updateItems();
		this.options.changes.subscribe(() => {
			this.updateItems();
			if (this.value) {
				this.selectItem(this.value);
			}
		});
	}

	onClear() {
		this.value = null;
		this.model = '';
		this.currentIndex = null;
		this.currentSelectedId = null;
	}

	onKeyDownEvent(event: any) {
		if (event.code === 'Backspace' || event.code === 'Delete') {
			this.onClear();
			return;
		}
		if (event.code === 'ArrowDown' || event.code === 'ArrowUp'
			|| event.code === 'Enter' || event.code === 'NumpadEnter'
			|| event.code === 'Tab' || event.code === 'ShiftLeft' || event.code === 'ShiftRight'
			|| event.code === 'Home') {

			this.getCurrentIndex(event);
			this.HandleItemSelection(event);
		} else {
			event.preventDefault();
		}
	}

	private getCurrentIndex(event: any) {
		if (event.code === 'ArrowDown') {
			if (this.currentIndex === null) {
				this.currentIndex = 0;
			} else if (this.allItems.length && this.allItems.length - 1 > this.currentIndex) {
				this.currentIndex = this.currentIndex + 1;
			}
		} else if (event.code === 'ArrowUp') {
			if (this.currentIndex > 0) {
				this.currentIndex = this.currentIndex - 1;
			}
		}
	}

	private HandleItemSelection(event: any) {
		if (this.currentIndex !== null) {
			if ((event.code === 'Enter' || event.code === 'NumpadEnter' || event.code === 'Tab')) {
				this.onItemSelected(this.allItems[this.currentIndex].value);
			}
			this.currentSelectedId = this.allItems[this.currentIndex].value;
			const element = document.getElementById(this.currentSelectedId);
			if (element) {
				element.scrollIntoView({ block: 'nearest' });
			}
		}
	}
}
