import { CommonModule } from '@angular/common';
import { NgModule, Pipe, PipeTransform } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { BasePipe } from 'app/generated/generated-common';
export enum TranshipmentType {
	None = 0,
	Direct = 1,
	Single = 2,
	Double = 4,
	Multiple = 8,
	All = 15,
}

@Pipe({
name: 'transhipmentType'
})
export class TranshipmentTypePipe extends BasePipe<TranshipmentType> implements PipeTransform {
	static prefix = 'Backend.Types.TranshipmentType.';
	static keys = [
		TranshipmentType.None,
		TranshipmentType.Direct,
		TranshipmentType.Single,
		TranshipmentType.Double,
		TranshipmentType.Multiple,
		TranshipmentType.All];
	constructor(
		protected translateService: TranslateService
	) {
		super(translateService);
	}
	toString(value: TranshipmentType): string {
		if (value == null) {
			return null;
		}
		switch (value) {
			case TranshipmentType.None: return 'None';
			case TranshipmentType.Direct: return 'Direct';
			case TranshipmentType.Single: return 'Single';
			case TranshipmentType.Double: return 'Double';
			case TranshipmentType.Multiple: return 'Multiple';
			case TranshipmentType.All: return 'All';
			default: return null;
		}
	}
	transform(value: TranshipmentType, suffix: string, interpolateParams?: Object) {
		const str = this.toString(value);
		if (str) {
			return this.translateOne(TranshipmentTypePipe.prefix + str, suffix, interpolateParams);
		}
		const keys = this.toKeys(TranshipmentTypePipe.keys, TranshipmentTypePipe.prefix, value, suffix);
		return this.translateArray(keys);
	}
}


@NgModule({
	imports: [
		CommonModule,
		TranslateModule
	],
	declarations: [
		TranshipmentTypePipe
	],
	exports: [
		TranshipmentTypePipe
	],
	providers: [
		TranshipmentTypePipe
	]
})
export class TranshipmentTypeModule { }
