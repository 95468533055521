import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { CoreButtonClearComponent } from 'app/core/button/CoreButtonClearComponent';
import { CoreButtonCreateComponent } from 'app/core/button/CoreButtonCreateComponent';
import { CoreButtonEditIconComponent } from 'app/core/button/CoreButtonEditIconComponent';
import { CoreButtonFetchComponent } from 'app/core/button/CoreButtonFetchComponent';
import { CoreButtonLoginRegisterComponent } from 'app/core/button/CoreButtonLoginRegisterComponent';
import { CoreButtonResetComponent } from 'app/core/button/CoreButtonResetComponent';
import { CommonMaterialModule } from '../common-material/core-common-material.module';
import { ButtonAddIconComponent } from './button-add-icon.component';
import { ButtonCancelComponent } from './button-cancel.component';
import { ButtonCheckoutComponent } from './button-checkout.component';
import { ButtonCloseComponent } from './button-close.component';
import { ButtonContactUsComponent } from './button-contact-us.component';
import { ButtonEditComponent } from './button-edit.component';
import { ButtonLogoutComponent } from './button-logout.component';
import { ButtonPreviousComponent } from './button-previous.component';
import { ButtonPublishComponent } from './button-publish.component';
import { ButtonRefreshComponent } from './button-refresh.component';
import { ButtonReloadComponent } from './button-reload.component';
import { ButtonRevertComponent } from './button-revert.component';
import { ButtonRevisionComponent } from './button-revision.component';
import { ButtonSaveIconComponent } from './button-save-icon.component';
import { ButtonSaveComponent } from './button-save.component';
import { ButtonSubmitComponent } from './button-submit.component';
import { ButtonUploadComponent } from './button-upload.component';
import { ButtonWizardComponent } from './button-wizard.component';
import { CancelButtonComponent } from './CancelButtonComponent';
import { CoreButtonAcceptComponent } from './CoreButtonAcceptComponent';
import { CoreButtonActionComponent } from './CoreButtonActionComponent';
import { CoreButtonAddComponent } from './CoreButtonAddComponent';
import { CoreButtonDeleteIconComponent } from './CoreButtonDeleteIconComponent';
import { CoreButtonGenericIconComponent } from './CoreButtonGenericIconComponent';
import { CoreButtonNextComponent } from './CoreButtonNextComponent';
import { CoreButtonRejectComponent } from './CoreButtonRejectComponent';
import { CoreButtonSearchComponent } from './CoreButtonSearchComponent';
import { ButtonTestComponent } from './CoreButtonTestComponent';
import { CoreButtonTranslateComponent } from './CoreButtonTranslateComponent';
import { CoreStartButtonComponent } from './CoreStartButtonComponent';
import { CoreStopButtonComponent } from './CoreStopButtonComponent';
import { DeleteButtonComponent } from './DeleteButtonComponent';
import { DownloadButtonComponent } from './DownloadButtonComponent';
import { ExcludeButtonComponent } from './ExcludeButtonComponent';
import { GenericButtonComponent } from './GenericButtonComponent';
import { InviteButtonComponent } from './InviteButtonComponent';
import { SendButtonComponent } from './SendButtonComponent';
import { ButtonOkComponent } from './button-ok.component';
import { ButtonRequestComponent } from './button-request.component';
import { CoreButtonCustomizeComponent } from './CoreButtonCustomizeComponent';

@NgModule({
	imports: [
		Angulartics2Module,
		CommonMaterialModule,
		CommonModule,
		RouterModule,
		TranslateModule,
	],
	exports: [
		ButtonAddIconComponent,
		ButtonCancelComponent,
		ButtonCheckoutComponent,
		ButtonCloseComponent,
		ButtonContactUsComponent,
		ButtonEditComponent,
		ButtonLogoutComponent,
		ButtonPreviousComponent,
		ButtonPublishComponent,
		ButtonRefreshComponent,
		ButtonReloadComponent,
		ButtonRevertComponent,
		ButtonRevisionComponent,
		ButtonSaveComponent,
		ButtonSaveIconComponent,
		ButtonSubmitComponent,
		ButtonTestComponent,
		ButtonUploadComponent,
		ButtonWizardComponent,
		CancelButtonComponent,
		CoreButtonAcceptComponent,
		CoreButtonActionComponent,
		CoreButtonAddComponent,
		CoreButtonClearComponent,
		CoreButtonCreateComponent,
		CoreButtonCustomizeComponent,
		CoreButtonDeleteIconComponent,
		CoreButtonEditIconComponent,
		CoreButtonFetchComponent,
		CoreButtonGenericIconComponent,
		CoreButtonLoginRegisterComponent,
		CoreButtonNextComponent,
		CoreButtonRejectComponent,
		CoreButtonResetComponent,
		CoreButtonSearchComponent,
		CoreButtonTranslateComponent,
		CoreStartButtonComponent,
		CoreStopButtonComponent,
		DeleteButtonComponent,
		DownloadButtonComponent,
		ExcludeButtonComponent,
		GenericButtonComponent,
		InviteButtonComponent,
		SendButtonComponent,
		ButtonOkComponent,
		ButtonRequestComponent
	],
	declarations: [
		ButtonAddIconComponent,
		ButtonCancelComponent,
		ButtonCheckoutComponent,
		ButtonCloseComponent,
		ButtonContactUsComponent,
		ButtonEditComponent,
		ButtonLogoutComponent,
		ButtonPreviousComponent,
		ButtonPublishComponent,
		ButtonRefreshComponent,
		ButtonReloadComponent,
		ButtonRevertComponent,
		ButtonRevisionComponent,
		ButtonSaveComponent,
		ButtonSaveIconComponent,
		ButtonSubmitComponent,
		ButtonTestComponent,
		ButtonUploadComponent,
		ButtonWizardComponent,
		CancelButtonComponent,
		CoreButtonAcceptComponent,
		CoreButtonActionComponent,
		CoreButtonAddComponent,
		CoreButtonClearComponent,
		CoreButtonCreateComponent,
		CoreButtonCustomizeComponent,
		CoreButtonDeleteIconComponent,
		CoreButtonEditIconComponent,
		CoreButtonFetchComponent,
		CoreButtonGenericIconComponent,
		CoreButtonLoginRegisterComponent,
		CoreButtonNextComponent,
		CoreButtonRejectComponent,
		CoreButtonResetComponent,
		CoreButtonSearchComponent,
		CoreButtonTranslateComponent,
		CoreStartButtonComponent,
		CoreStopButtonComponent,
		DeleteButtonComponent,
		DownloadButtonComponent,
		ExcludeButtonComponent,
		GenericButtonComponent,
		InviteButtonComponent,
		SendButtonComponent,
		ButtonOkComponent,
		ButtonRequestComponent
	]
})
export class CoreButtonModule { }
