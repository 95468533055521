<button mat-menu-item #profileLink="routerLinkActive" routerLinkActive="adn-color-primary"
  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/user', 'welcome']"
  class="adnavem-text-color-with-primary-background"
  [ngClass]="{'adnavem-dark-text-with-light-background': !profileLink.isActive, 'adnavem-primary-with-light-background': profileLink.isActive && isSideMenu}">
  <span class="fal fa-material fa-fw fa-home adn-menu-icons" aria-hidden="true"></span>&nbsp;{{'nav.welcome' |
  translate}}
</button>
<button mat-menu-item #profileLink="routerLinkActive" routerLinkActive="adn-color-primary"
  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/user', 'settings']"
  [ngClass]="{'adnavem-dark-text-with-light-background': !profileLink.isActive, 'adnavem-primary-with-light-background': profileLink.isActive && isSideMenu}">
  <span class="fal fa-material fa-fw fa-user-circle adn-menu-icons" aria-hidden="true"></span>&nbsp;{{'nav.settings' |
  translate}}
</button>
<button mat-menu-item #notificationsLink="routerLinkActive" routerLinkActive="adn-color-primary"
  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/user', 'notifications']"
  [ngClass]="{'adnavem-dark-text-with-light-background': !notificationsLink.isActive, 'adnavem-primary-with-light-background': notificationsLink.isActive && isSideMenu}">
  <span class="fal fa-material fa-fw fa-bell adn-menu-icons" aria-hidden="true"></span>
  <span class="fal fa-material fa-fw fa-cog second-icon" aria-hidden="true"></span>
  <span class="second-icon-label-adjustment">&nbsp;{{'nav.notifications' | translate}}</span>
</button>
<button mat-menu-item #consentsLink="routerLinkActive" routerLinkActive="adn-color-primary"
  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/user', 'consents']"
  [ngClass]="{'adnavem-dark-text-with-light-background': !consentsLink.isActive, 'adnavem-primary-with-light-background': consentsLink.isActive && isSideMenu}">
  <span class="fal fa-material fa-fw fa-handshake adn-menu-icons" aria-hidden="true"></span>
  <span class="fal fa-material fa-fw fa-cog second-icon" aria-hidden="true"></span>
  <span class="second-icon-label-adjustment">&nbsp;{{'nav.consents' | translate}}</span>
</button>
<button mat-menu-item #partyLink="routerLinkActive" routerLinkActive="adn-color-primary"
  [routerLinkActiveOptions]="{exact: true}" [routerLink]="['/user', 'parties']"
  [ngClass]="{'adnavem-dark-text-with-light-background': !partyLink.isActive, 'adnavem-primary-with-light-background': partyLink.isActive && isSideMenu}">
  <span class="fal fa-material fa-fw fa-building adn-menu-icons" aria-hidden="true"></span>&nbsp;{{'nav.parties' |
  translate}}
</button>
<button mat-menu-item (click)="doLogout()" name='logout' class="adnavem-dark-text-with-light-background">
  <span class="fal fa-material fa-fw fa-sign-out-alt adn-menu-icons" aria-hidden="true"></span>&nbsp;{{'button.logout' |
  translate}}
</button>